import NProgress from 'nprogress' // progress bar
import errorCode from '../../const/errorCode'
import router from './index'
import { Message } from 'element-ui'
import 'nprogress/nprogress.css'
import qs from 'qs'
import store from '@/store' // progress bar style
axios.defaults.timeout = 30000
console.log(process.env.NODE_ENV,'env')
// axios.defaults.baseURL = process.env.NODE_ENV == 'development' ? '/' :'https://gateway.duoge.work'

const serialize = data => {
  const list = []
  Object.keys(data).forEach(ele => {
    list.push(`${ele}=${data[ele]}`)
  })
  return list.join('&')
}
export const getObjType = obj => {
  var toString = Object.prototype.toString
  var map = {
    '[object Boolean]': 'boolean',
    '[object Number]': 'number',
    '[object String]': 'string',
    '[object Function]': 'function',
    '[object Array]': 'array',
    '[object Date]': 'date',
    '[object RegExp]': 'regExp',
    '[object Undefined]': 'undefined',
    '[object Null]': 'null',
    '[object Object]': 'object'
  }
  if (obj instanceof Element) {
    return 'element'
  }
  return map[toString.call(obj)]
}

const instance = axios.create({
  // baseURL: '/api', // 测试环境
  baseURL: '/', // 测试环境
});
// 返回其他状态吗
instance.defaults.validateStatus = function (status) {
  return status >= 200 && status <= 500 // 默认的
}
// 跨域请求，允许保存cookie
instance.defaults.withCredentials = true
// NProgress Configuration
NProgress.configure({
  showSpinner: false
})

// HTTPrequest拦截
instance.interceptors.request.use(config => {
  NProgress.start() // start progress bar
  // 速点默认14
  const TENANT_ID = 11 //  getStore({ name: 'tenantId' })
  const isToken = (config.headers || {}).isToken === false
  const token = store.getters.access_token
  if (token && !isToken) {
    config.headers['Authorization'] = 'Bearer ' + token// token
  }
  if (TENANT_ID) {
    config.headers['TENANT-ID'] = TENANT_ID // 租户ID
  }
  config.headers['TENANT-EXTRA'] = '6753d59451c56bd1ca6b8a9c13877a3a'
  if(config.upload) {
    config.headers['Content-Type'] = 'multipart/form-data'
  }
  // headers中配置serialize为true开启序列化
  if (config.method === 'post' && config.headers.serialize) {
    config.data = serialize(config.data)
    delete config.data.serialize
  }
  if (config.method === 'get') {
    config.paramsSerializer = function (params) {
      return qs.stringify(params, { arrayFormat: 'repeat' })
    }
  }
  return config
}, error => {
  return Promise.reject(error)
})

// HTTPresponse拦截
instance.interceptors.response.use((res, req) => {
  NProgress.done()
  const status = Number(res.status) || 200
  const message = res.data.msg || errorCode[status] || errorCode['default']
  if ((status === 401 || status === 424) && window.location.href.indexOf('/login') === -1) {
    Message({
      message: message,
      type: 'error'
    })
    router.push({ path: '/login' })
    store.dispatch('LogOut').then(() => {
    })
    return
  }

  if (status !== 401 && (status !== 200 || res.data.code === 1)) {
    Message({
      message: message,
      type: 'error'
    })
    return Promise.reject(new Error(message))
  }
  return res.data
}, error => {
  NProgress.done()
  return Promise.reject(new Error(error))
})

export default instance
