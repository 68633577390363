const geoCoordMap = {
    '江西': [115.892151,28.676493],
    '河南': [113.065412,33.757975],
    '四川': [102.065735,30.659462],
    '重庆': [106.504962,29.533155],
    '西藏': [91.132212,29.660361],
    '贵州': [106.713478,26.578343],
    '辽宁': [123.429096,41.796767],
    '新疆': [87.617733,43.792818],
    '山东': [117.000923,36.675807],
    '上海': [121.472644,31.231706],
    '澳門': [113.54909,22.198951],
    '山西': [111.549248,37.857014],
    '浙江': [120.153576,29.287459],
    '海南': [108.93119,19.031971],
    '福建': [117.306239,26.075302],
    '青海': [95.778916,36.623178],
    '宁夏': [105.278179,37.46637],
    '湖北': [112.298572,30.584355],
    '甘肃': [103.023557,36.058039],
    '安徽': [116.983042,31.86119],
    '台湾': [121.509062,25.044332],
    '陕西': [108.948024,34.263161],
    '广西': [108.320004,22.82402],
    '天津': [116.790182,39.125596],
    '云南': [100.712251,24.040609],
    '黑龙江': [126.642464,45.756967],
    '广东': [113.280637,23.125178],
    '湖南': [110.982279,28.19409],
    '河北': [114.502461,38.045474],
    '内蒙古': [111.670801,41.818311],
    '吉林': [125.3245,43.886841],
    '江苏': [119.167413,32.041544],
    '北京': [115.405285,39.904989],
    '香港': [114.173355,22.320048]
}


const getMapOption = ({ data = [], img, imgIcon, imgIconActive, imgActive, activeName, imgRed, pname = '', imgRedIcon }) => {

  // data元素截取前2个字符，如：北京市 => 北京，再与geoCoordMap做比对
  let sliceData = data.map(item => item ? (item.indexOf('黑龙江') != -1 || item.indexOf('内蒙古') != -1 ? item.substr(0,3) : item.substr(0,2)) : null) 
  
  return ({
      geo: {
          map: 'world',
          top: 100,
          bottom: 50,
          left: 25,
          right: 100,
          emphasis: {
            label: {
              show: true
            },
              borderColor: 'rgb(255,255,255)',
              borderWidth: 1.5,
              areaColor: 'rgb(0,154,251)',
            shadowBlur: 15,
            shadowOffsetY: 2,
          },
          itemStyle: {
            normal: {
                borderColor: 'rgb(255,255,255)',
                borderWidth: 1.5,
                areaColor: 'rgb(0,154,251)',
                opacity: 0.8,
            },
            emphasis: {
                label: {
                  show: true
                },
                scaleSize: 2,
                scale: true,
                borderColor: 'rgb(0,0,0)',
                areaColor: 'rgb(253,163,106)',
                shadowColor: 'rgb(255,255,255)',
                shadowBlur: 15,
                shadowOffsetY: 2,
            }
          },
          silent: false,
          roam: true,
          z: 0,
          scaleLimit:{
              min:1,
              max:2
          }
      },
      series: [
        // {
        // type: 'map',
        // map: 'world',
        // clip: true,
        // top: 0,
        // bottom: 50,
        // left: 0,
        // right: 100,
        // tooltip: {
        //     show: false
        // },
        // label: {
        //     show: false,
        //     color: '#F0F0F0',
        //     fontSize: 16,
        //     fontWeight: 'bold',
        //     fontFamily: 'PingFang',
        // },
        // itemStyle: {
        //     normal: {
        //         borderColor: 'rgb(255,255,255)',
        //         borderWidth: 1.5,
        //         areaColor: 'rgb(0,154,251)',
        //         opacity: 0.8,
        //     },
        //     emphasis: {
        //         label: {
        //           show: true
        //         },
        //         scaleSize: 2,
        //         scale: true,
        //         borderColor: 'rgb(0,0,0)',
        //         areaColor: 'rgb(253,163,106)',
        //         shadowColor: 'rgb(255,255,255)',
        //         shadowBlur: 15,
        //         shadowOffsetY: 2,
        //     }
        //   },
        // },
        {
            type: 'effectScatter',
            coordinateSystem: 'geo',
            symbol: 'circle',
            symbolOffset: [0, -16],
            symbolSize: 10,
            rippleEffect: {
               number: 15,
               period: 5,
               scale: 3,
            },
            z: 0,
            data: Object.keys(geoCoordMap).filter(item => sliceData.indexOf(item) > -1).map(item => ({
              name: item,
              value: geoCoordMap[item],
              itemStyle: {
                symbol: activeName === item ? imgIconActive : (pname.indexOf(item) > -1 ? imgRedIcon : imgIcon),

                color: activeName === item ? '#fe7127' : (pname.indexOf(item) > -1 ? "#b21a1a" : "#289aff"),
              },
              symbolSize: activeName === item ? 15 : 10,
              symbolOffset: activeName === item ? [0, -10] : [0, -16],
            })),
        },
        {
            type: 'scatter',
            coordinateSystem: 'geo',
            itemStyle: {
                color: '#f00',
            },
            symbol: imgIcon,
            symbolSize: [20, 26],
            symbolOffset: [0, -26],
            z: 9999,
            data: Object.keys(geoCoordMap).filter(item => sliceData.indexOf(item) > -1).map(item => ({
              name: item,
              value: geoCoordMap[item],
              symbol: activeName === item ? imgIconActive : (pname.indexOf(item) > -1 ? imgRedIcon : imgIcon),
              symbolSize: activeName === item ? [26, 39] : [20, 26],
            })),
        },
        {
            type: 'scatter',
            coordinateSystem: 'geo',
            label: {
                normal: {
                    show: true,
                    color: '#F0F0F0',
                    fontSize: 12,
                    fontWeight: 'bold',
                    fontFamily: 'PingFang',
                    formatter: function (params) {
                        var text = params.name;
                        return text;
                    },
                },
                emphasis: {
                    show: true,
                },
            },
            itemStyle: {
                color: '#00FFF6',
            },
            symbol: img,
            symbolSize: [60, 28],
            symbolOffset: [0, -60],
            z: 999,
            data: Object.keys(geoCoordMap).filter(item => sliceData.indexOf(item) > -1).map(item => ({
              name: item,
              value: geoCoordMap[item],
              symbol: activeName === item ? imgActive : (pname.indexOf(item) > -1 ? imgRed : img),
              symbolSize: activeName === item ? [61, 29] : [60, 28],
            })),
        }
  	  ]
  })
};

export default getMapOption;
