<template>
  <div class="login-main">
    <div class="content">
      <div class="left">
        <div class="title-top">Welcome!</div>
        <div class="title-bottom">欢迎使用</div>
        <div class="title-bottom">龙王可视化消防管理系统</div>
      </div>
      <div class="right">
        <div class="logo">
          <img class="logo-img" src="../assets/images/login/logo.png" />
          <img class="logo-title" src="../assets/images/login/logo-title.png" />
        </div>
        <el-form
          ref="loginForm"
          :rules="type === 1 ? loginRules : loginCodeRules"
          :model="form"
          class="login-form"
          status-icon
          label-width="0"
        >
          <el-form-item prop="username" v-if="type === 1">
            <div class="username">
              <img src="../assets/images/login/username.png" />
              <el-input v-model="form.username" placeholder="请输入你的账号" type="text" />
            </div>
          </el-form-item>
          <el-form-item prop="password" v-if="type === 1">
            <div class="username">
              <img src="../assets/images/login/password.png" />
              <el-input v-model="form.password" placeholder="请输入密码" type="password" />
            </div>
          </el-form-item>
          <el-form-item prop="mobile" v-if="type === 2">
            <div class="username">
              <img src="../assets/images/login/phone.png" />
              <el-input v-model="form.mobile" placeholder="请输入你的手机号" type="text" />
            </div>
          </el-form-item>
          <el-form-item prop="code" v-if="type === 2">
            <div class="password">
              <div class="">
                <img src="../assets/images/login/password.png" />
                <el-input v-model="form.code" placeholder="请输入验证码" type="text" />
              </div>
              <div class="getcode cursor" @click="handleSend">{{ msgText }}</div>
            </div>
          </el-form-item>
        </el-form>
        <div class="code cursor" v-if="type === 1"><span @click="changeType">验证码登录</span></div>
        <div class="code cursor" v-else><span @click="changeType">账号密码登录</span></div>
        <div class="button cursor" @click="handleLogin">登录</div>
      </div>
    </div>
    <Verify
      @success="verifySuccess"
      :mode="'pop'"
      :captchaType="'blockPuzzle'"
      :imgSize="{ width: '330px', height: '155px' }"
      ref="verify"
    />
  </div>
</template>

<script>
import qs from 'qs'
import request from "@/router/axios";
import { mapMutations } from 'vuex'
import { isvalidatemobile } from "@/utils/validate";
import {getStore, setStore} from '@/utils/store'
import Verify from "../components/verifition/Verify";
import {getUserInfo} from '@/api/login'


const MSGINIT = "发送验证码"
const MSGSCUCCESS = "${time}秒后重发"
const MSGTIME = 60

export default {
  data() {
    const validatePhone = (rule, value, callback) => {
      if (isvalidatemobile(value)[0]) {
        callback(new Error(isvalidatemobile(value)[1]));
      } else {
        callback();
      }
    };
    const validateCode = (rule, value, callback) => {
      if (value && value.length != 4) {
        callback(new Error("请输入4位数的验证码"));
      } else {
        callback();
      }
    };
    const strongPwd = (rule, value, callback) => {
       let reg = /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,20}$/
       if (value === '') {
          callback(new Error('请输入密码'));
        } else if(!reg.test(value)){
          callback(new Error('密码包含大小写字母、数字、符号，不含空格，8-20位'));
        } else {
          callback();
        }
      };
    return {
      checked: false,
      type: 1,
      form: {
        username: "",
        password: "",
        code: "",
        mobile: "",
        randomStr: "blockPuzzle"
      },
      msgText: MSGINIT,
      msgTime: MSGTIME,
      msgKey: false,
      loginRules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "change" },
          // { required: true, message: "请输入用户名", trigger: "blur" }
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "change" },
          { required: true, message: "请输入密码", trigger: "blur" },
          // { min: 6, message: "密码长度最少为6位", trigger: "change" }
          { validator: strongPwd, trigger: 'change' },
          { validator: strongPwd, trigger: 'blur' }
        ]
      },
      loginCodeRules: {
        mobile: [
          { required: true, trigger: "change", validator: validatePhone },
          { required: true, trigger: "blur", validator: validatePhone }
        ],
        code: [
          { required: true, trigger: "change", validator: validateCode },
          { required: true, trigger: "blur", validator: validateCode }
        ],
      },
    }
  },
  mounted() {
  },
  components: {
    Verify
  },
  methods: {
     ...mapMutations(['SET_USER_INFO']),
    changeType() {
      this.form.code = ""
      this.$refs.loginForm.clearValidate()
      this.type = this.type === 1 ? 2 : 1
    },
    handleSend() {
      // 判断是否可以发送（时间限制）
      if (this.msgKey) return;
      // 发送验证码
      this.$refs.loginForm.validateField("mobile", (valid) => {
        if (!valid) {
          request({
            url: "/admin/mobile/longwang/" + this.form.mobile,
            method: "get",
          }).then((response) => {
            if (response.data) {
              this.$message.success("验证码发送成功");
              this.timeCacl();
            } else {
              this.$message.error(response.data.msg);
            }
          });
        }
      });
    },
    timeCacl() {
      // 计时避免重复发送
      this.msgText = MSGSCUCCESS.replace("${time}", this.msgTime);
      this.msgKey = true;
      const time = setInterval(() => {
        this.msgTime--;
        this.msgText = MSGSCUCCESS.replace("${time}", this.msgTime);
        if (this.msgTime === 0) {
          this.msgTime = MSGTIME;
          this.msgText = MSGINIT;
          this.msgKey = false;
          clearInterval(time);
        }
      }, 1000);
    },
    async handleLogin() {
      if (this.type === 2) {
        this.$refs.loginForm.validate((valid) => {
          if (valid) {
            this.$store.dispatch("LoginByPhone", this.form).then(() => {
              this.getUserInfo() // 查看是否有权限
            });
          }
        });
      } else {
        this.$refs.loginForm.validate((valid) => {
          if (valid) {
            this.$refs.verify.show();
          }
        });
      }
    },
    getUserInfo() {
      // this.$api.get('/longwang-service-biz/user/info')
      // this.$api.get('/admin/user/info')
      getUserInfo().then(({ data }) => {
         // LONG_ADMIN 超级管理员, LONG_GROUP_ADMIN 区域管理员, LONG_ZONE_ADMIN园区管理员
        //  if (['LONG_ADMIN', 'LONG_GROUP_ADMIN', 'LONG_ZONE_ADMIN'].indexOf(data.roleCodes) > -1) {
        let userRoleName = data.sysUser.userRoleName.replace(/\[|\]/g, ''); 
         if (['LONG_ADMIN', 'LONG_GROUP_ADMIN', 'LONG_ZONE_ADMIN'].indexOf(userRoleName) > -1) {
          //  this.SET_USER_INFO(data)
           this.SET_USER_INFO(data.sysUser)
           this.$router.push({ path: '/' });
         } else {
          //  this.SET_USER_INFO(data)
           this.SET_USER_INFO(data.sysUser)
           this.$message.error('抱歉，您暂时无权限登录！')
         }
       })
    },
    verifySuccess(params) {
      this.form.code = params.captchaVerification;
      this.$store.dispatch("LoginByUsername", this.form).then(() => {
        this.getUserInfo() // 查看是否有权限
      });
    }
  }
}

</script>

<style lang="scss">
.login-main {
  background: url("../assets/images/index/bg1.png") no-repeat;
  background-size: contain;
  width: 1920px;
  height: 1080px;
  position: relative;
  .content {
    display: flex;
    justify-content: center;
    padding-top: 199px;
    .left {
      width: 582px;
      height: 681px;
      background: rgba(26, 40, 85, 0.59);
      padding-left: 98px;
      padding-top: 250px;
      text-align: left;
      .title-top {
        font-size: 48px;
        font-family: AlibabaPuHuiTi;
        font-weight: 400;
        color: #FBF6F4;
      }
      .title-bottom {
        font-size: 40px;
        font-family: AlibabaPuHuiTiBold;
        font-weight: 400;
        color: #FBF6F4;
        margin-top: 25px;
      }
    }
    .right {
      width: 620px;
      height: 681px;
      padding-top: 80px;
      padding-left: 55px;
      padding-right: 55px;
      background: #FFFFFF;
      .logo {
        display: flex;
        align-items: center;
        margin-bottom: 40px;
        .logo-img {
          width: 74px;
          height: 74px;
          margin-right: 8px;
        }
        .logo-title {
          width: 139px;
          height: 28px;
        }
      }
      .el-form-item__content {
        margin-bottom: 20px;
      }
      .username, .password {
        width: 500px;
        border-bottom: 1px solid #ccc;
        padding: 20px 0;
        display: flex;
        img {
          width: 31px;
          height: 34px;
          margin-right: 16px;
        }
        input {
          border: none;
          outline:none;
          font-size: 24px;
          font-family: AlibabaPuHuiTi;
          font-weight: 400;
          color: #000;
        }
        div {
          display: flex;
          align-items: center;
        }
        .getcode {
          font-size: 20px;
          font-family: AlibabaPuHuiTi;
          font-weight: 400;
          color: #999;
          align-items: center;
        }
      }
      .password {
        justify-content: space-between;
      }
      .code {
        span {
          font-size: 20px;
          font-family: AlibabaPuHuiTi;
          font-weight: 400;
          color: #2993F0;
        }
        text-align: right;
      }
      .button {
        width: 500px;
        height: 80px;
        line-height: 80px;
        margin-top: 86px;
        background: #2A92F0;
        border-radius: 40px;
        font-size: 30px;
        font-family: AlibabaPuHuiTi;
        font-weight: bold;
        color: #FFFFFF;
      }
    }
  }
}

</style>
