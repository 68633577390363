<template>
    <div id="container">
        <div v-if="showButton" style="position: absolute;right: 130px; top: 5px;z-index:10000;"  @click="show">
            <div class="showBtn cursor">
            </div>
        </div>
        <headers></headers>
        <center
            :groupParkId="groupParkId"
            :isQuanAdmin="isQuanAdmin"
            :isGroupAdmin="isGroupAdmin"
            :changeGroupParkId="changeGroupParkId"
            :isAdmin="isAdmin"
            v-if="chinaMapStatus"/>
        <!--            <center-world v-if="worldMapStatus" @func="getMsg"></center-world>-->
        <worldMap :groupParkId="groupParkId"
                  :isQuanAdmin="isQuanAdmin"
                  :changeGroupParkId="changeGroupParkId"
                  :isAdmin="isAdmin" v-if="worldMapStatus" @func="getMsg"></worldMap>

        <left :groupParkId="groupParkId" :isQuanAdmin="isQuanAdmin"/>
        <right :groupParkId="groupParkId" :isQuanAdmin="isQuanAdmin"/>
        <bottom :groupParkId="groupParkId"/>

    </div>
</template>

<script>
import {getStore} from "@/utils/store";
import headers from "@/components/header.vue";
import left from "@/components/left.vue";
import right from "@/components/right.vue";
import bottom from "@/components/bottom.vue";
import center from "@/components/center.vue";
// import CenterWorld from "@/components/centerWorld.vue";
import worldMap from "@/components/worldMap.vue";

import ECharts from "vue-echarts";
import "echarts/lib/chart/line";
import "echarts/lib/chart/bar";
import moment from "moment";

let wsClient = null;
export default {
    name: "HelloWorld",
    components: {
        headers,
        left,
        right,
        bottom,
        center,
        "v-chart": ECharts,
        // CenterWorld,
        worldMap
    },

    data() {
        return {
            titles: "龙王可视化消防管理系统",
            isAdmin: getStore({name: "userInfo"}).userRoleName.replace(/\[|\]/g, '') === "LONG_ADMIN",//全国管理员
            isQuanAdmin: getStore({name: "userInfo"}).userRoleName.replace(/\[|\]/g, '') !== "LONG_ADMIN",
            groupParkId:
                getStore({name: "userInfo"}).userRoleName.replace(/\[|\]/g, '') !== "LONG_ADMIN"
                    // ? getStore({name: "userInfo"}).parkId
                    ? getStore({name: "userInfo"}).sysUser.deptId
                    : "",
            // groupParkId: getStore({name: "userInfo"}).sysUser.deptId,
            isGroupAdmin:
                getStore({name: "userInfo"}).userRoleName.replace(/\[|\]/g, '') === "LONG_GROUP_ADMIN", //区域管理员
            isZoneAdmin:
                getStore({name: "userInfo"}).userRoleName.replace(/\[|\]/g, '') === "LONG_ZONE_ADMIN", //园区管理员
            showButton: false,
            // buttonText: "world map",
            chinaMapStatus: false,
            worldMapStatus: false,
            mapControl: false,//true为世界地图，false为中国地图
        };
    },
    // created() {
    //   this.changeAdminStatus();
    // },
    mounted() {
        this.changeAdminStatus();
    },
    methods: {
        changeGroupParkId(id) {
            console.log("ParkId是"+id);
            this.isQuanAdmin = id ? true : false
            this.groupParkId = id || ''
            this.showButton = false;

            if (id === undefined){
                this.setChinaMap()
                console.log("没ID哦")
            }
        },
        changeAdminStatus() {
            console.log("初始化")
            if (getStore({name: "userInfo"}).userRoleName.replace(/\[|\]/g, '') === "LONG_ADMIN") {
                console.log(getStore({name: "userInfo"}).userRoleName.replace(/\[|\]/g, ''))
                this.worldMapStatus = true;
                this.chinaMapStatus = false;
                this.mapControl = true;
                document.getElementById("container").setAttribute('class', 'container-world container')
            }else{
                this.chinaMapStatus = true;
                this.mapControl = false;
                document.getElementById("container").setAttribute('class','container-china admin container')
            }
        },
        show() {
            this.mapControl = !this.mapControl;
            if (this.mapControl === true) {
                this.worldMapStatus = true;
                this.chinaMapStatus = false;
                this.showButton = false;
                document.getElementById("container").setAttribute('class', 'container-world container')
            } else {
                this.setChinaMap()
            }
        },
        setChinaMap(){
            document.getElementById("container").setAttribute('class', 'container-china container')
            this.worldMapStatus = false;
            this.showButton = true
            this.chinaMapStatus = true;
            // this.buttonText = "世界地图"
        },
        getMsg(data) {
            if (data === "China") {
                this.show()
            }
        }
    },
};
</script>

<style lang="scss" scoped>
@import "../assets/css/pubilc.css";
@import "../assets/css/font.css";
@import "../assets/scss/line.scss";

.container-china {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 1920px;
    height: 1080px;
    padding: 0 30px;
    box-sizing: border-box;
    background: url("../assets/images/index/bg.png") no-repeat center;
    background-size: contain;

    &.admin {
        background: url("../assets/images/index/bg1.png") no-repeat center;
    }
}

.container-world {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 1920px;
    height: 1080px;
    padding: 0 30px;
    box-sizing: border-box;
}

.showBtn {
    height: 45px;
    line-height: 54px;
    width: 218px;
    background: url("../assets/images/index/world-icon.png") no-repeat
    center;
    background-size: contain;
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #ffffff;
    margin: 0 auto;
    &.active {
        background: url("../assets/images/index/world-icon.png") no-repeat
        center;
    }
}

</style>
