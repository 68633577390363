/**
 * 此处可直接引用自己项目封装好的 axios 配合后端联调
 */


import request from '@/router/axios'

//获取验证图片  以及token
export function getPointManagementInfo(params) {
    return request({
        url: `/longwang-service-biz/pointmanagement/alertTask/${params}`,
        method: 'get',
    })
}

// 获取设备样式树
export function getDevicePointTypeTree(query){
    return request({
        url: '/longwang-service-biz/devicetype/tree',
        method: 'get',
        params: query
    })
}

// 查询关联点位信息
export function getRelevancePointInfo(obj){
    return request({
        url: '/longwang-service-biz/pointmanagement/slide',
        method: 'post',
        data: obj
    })
}

// 获取楼层树
export function fetchFloorTree(query) {
    return request({
        url: '/admin/dept/tree',
        method: 'get',
        params: query
    })
}

// 根据楼层ID查询点位关联
export function getPointManagementByFloorId(query){
    return request({
        url: `/longwang-service-biz/pointmanagement/floorMap/${query}`,
        method: 'get',
    })
}
