<template>
  <div class="point-examine-container">
    <div class="point-header">
      <img src="../../assets/images/point-examine/point-header-bg.png">
    </div>
    <div class="point-body">
      <div class="operation">
        <div class="go-back" @click="goBack">回到园区</div>
        <!--<div class="point-choose">
          <el-select v-model="floorId" placeholder="请选择楼层" popper-class="point-select" @change="pointSelect">
            <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </div>-->
      </div>
      <div id="point-container"></div>
    </div>
    <div class="point-footer">
      <img src="../../assets/images/point-examine/point-footer-bg.png">
    </div>
  </div>
</template>

<script>
import konva from 'konva'
import {getPointManagementInfo,
  getDevicePointTypeTree,
  getRelevancePointInfo,
  fetchFloorTree,
  getPointManagementByFloorId} from './api/index'
export default {
  name: "PointExamine",
  props: {
    detailInfo: {
      type: Object,
      require: true
    },
  },
  data(){
    return{
      floorId: 2497,
      options: [{
        value: 2497,
        label: '新楼层/1F'
      }, {
        value: 2496,
        label: '新楼层/2F'
      }, {
        value: 2499,
        label: '新楼层/3F'
      }],
      planeCode: "",
      pointManagements: [],
      imageSrc: "",
      width: 1000,
      height: 500,
      stage: {},
      stageConfig: {
        width: this.width,
        height: this.height,
        id: 'my-stage'
      },
      layer: {},
      devicePointTypeTree: [],
    }
  },
  mounted() {
    const {reqFetchFloorTree} = this;
    reqFetchFloorTree();
  },
  methods: {
    goBack(){
      this.$emit('goBack');
    },
    reqPointManagementInfo(pointDetailInfo){
      this.planeCode = pointDetailInfo.planeCode;
      this.imageSrc = pointDetailInfo.imageSrc;
      this.pointManagements = pointDetailInfo.pointManagements;
      const {createStage, createLayer, createImage} = this;
      createStage();
      createLayer();
      createImage();
      // let params = "5bc3f05fad3841e284314cf2d158753e";
      /*let params = this.detailInfo.contextId;
      getPointManagementInfo(params).then(res => {
        console.log("===getPointManagementInfo====")
        console.log(res)
        if(res.success){
          this.planeCode = res.data.coordinate;
          this.imageSrc = res.data.icon;
          this.pointManagements = res.data.managements;
          const {createStage, createLayer, createImage} = this;
          createStage();
          createLayer();
          createImage();
        }
      }).catch(err => {
        console.log(err)
      })*/
    },
    createStage(){
      const that = this;
      let stage = {};
      let planeCode = that.planeCode;
      if(planeCode !== "" || planeCode !== null){
        stage = konva.Node.create(planeCode, 'point-container')
        // todo 初始化标记点
        that.reqDevicePointTypeTree(stage)
      }
      // todo 初始化事件
      that.createStageEvent(stage);
      that.stage = stage;
    },
    createLayer(){
      const that = this;
      let stage = that.stage;
      let layer = stage.find("#my-layer");
      let myLayer = {};
      if(layer.length !== 0){
        that.layer = layer[0];
      }
    },
    createImage(){
      const that = this;
      let stage = that.stage;
      let myFloorImg = stage.find("#my-floor-img");
      let imageObj = new Image();
      if(myFloorImg.length !== 0){
        imageObj.onload = function (){
          let konvaImg = myFloorImg[0];
          konvaImg.image(imageObj)
          const ratio = imageObj.width / imageObj.height;
          that.stage.height(that.stage.width() / ratio);
          that.layer.scale({
            x: that.stage.width() / imageObj.width,
            y: that.stage.height() / imageObj.height,
          });
          stage.draw()
        }
      }
      imageObj.src = that.imageSrc
    },
    initMarkCustom(stage, devicePointTypeTree){
      const that = this;
      let markGroup = stage.find(".mark-group");
      console.log("======initMarkCustom=====")
      console.log(markGroup)
      markGroup.forEach(function (item) {
        item.draggable(false);
        if(item.getChildren().length > 0){
          item.getChildren().forEach(function (it) {
            if(it.getClassName() === "Image"){
              let name = it.getAttrs().name;
              // console.log(`name：${name}`)
              let imageObj = new Image();
              imageObj.onload = function (){
                it.image(imageObj)
                it.width(80)
                it.height(80)
                stage.draw()
              }
              let pointId = name.substring(name.lastIndexOf('-') + 1,name.length);
              let tree = devicePointTypeTree;
              let devicePointType = that.treeFind(tree,node => node.id.toString() === pointId);
              imageObj.src = devicePointType.icon;
              let konvaImg = it;
              konvaImg.on('click', function(evt){
                evt.cancelBubble = true;
              })
            }
            if(it.getClassName() === "Label"){
              it.visible(false)
              it.on('click', function (evt) {
                // let markCustomId = evt.currentTarget.getParent().getAttrs().id;
                // that.$emit('point-relevance', markCustomId)
                evt.cancelBubble = true;
              })
            }
          })
        }

        let markCustomId = item.id();
        let pointManagements = that.pointManagements;
        if(pointManagements.length > 0){
          if(pointManagements[0].markId === markCustomId){
            item.moveToTop();
            // 添加鼠标进入进出事件
            item.on('mouseover', function (){
              that.stage.container().style.cursor = 'pointer';
              let markCustomId = item.getAttrs().id;
              // that.$emit('mousemove-point-relevance', markCustomId)
              if(item.hasChildren()){
                item.getChildren(function (node) {
                  if(node.getClassName() === "Label"){
                    that.reqRelevancePointInfo(node, markCustomId);
                  }
                })
              }
            })
            item.on('mouseout',function (){
              that.stage.container().style.cursor = 'grab';
              if(item.hasChildren()){
                item.getChildren(function (node) {
                  if(node.getClassName() === "Label"){
                    node.visible(false);
                    // console.log("false")
                  }
                })
              }
            })

            let period = 4000;
            let anim = new konva.Animation(function (frame) {
              let scale = Math.abs(Math.sin((frame.time * 2 * Math.PI) / period)) + 0.25;
              // console.log(scale)
              if(item.hasChildren()){
                item.getChildren(function (node) {
                  if(node.getClassName() === "Image"){
                    node.scale({x: scale, y: scale})
                  }
                })
              }
            })
            anim.start();
          }
        }
      })
    },
    reqDevicePointTypeTree(stage){
      let query = {};
      getDevicePointTypeTree(query).then(res => {
        console.log("=====getDevicePointTypeTree=====")
        // console.log(res)
        if (res.success){
          let devicePointTypeTree = res.data;
          this.initMarkCustom(stage, devicePointTypeTree);
        }
      })
    },
    treeFind(tree,func){
      for (const data of tree) {
        if (func(data)) return data
        if (data.children) {
          const res = this.treeFind(data.children, func)
          if (res) return res
        }
      }
      return null
    },
    reqRelevancePointInfo(node, markCustomId){
      let query = {}
      query.markId = markCustomId;

      getRelevancePointInfo(query).then(res => {
        console.log("=====getRelevancePointInfo======")
        // console.log(res)
        let data = res.data;
        if(data !== null){
          if(data.deviceId !== null){
            let obj = data;
            let deviceId = obj.deviceId;
            let deviceName = obj.deviceName;
            let deviceType = obj.deviceType;
            let textStr = "设备编号："+deviceId+"\n设备类型："+deviceType+"\n安装位置："+deviceName+"";
            if(node.getClassName() === "Label"){
              node.getText().text(textStr);
            }
          }
        }
        node.visible(true);
        // console.log("true")
      })
    },
    createStageEvent(stage){
      const that = this;
      stage.container().style.cursor = 'grab';
      // 鼠标滚轮事件
      const scaleBy = 1.1;
      stage.on('wheel', e => {
        e.evt.preventDefault();
        const oldScale = stage.scaleX();

        const mousePointTo = {
          x: stage.getPointerPosition().x / oldScale - stage.x() / oldScale,
          y: stage.getPointerPosition().y / oldScale - stage.y() / oldScale
        };

        let newScale =
            e.evt.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy;

        if (newScale < 1) {
          newScale = 1;
        }

        const filterPos = (pos, scale) => {
          const stageHeight = (stage.height() * scale) - stage.height();
          const stageWidth = (stage.width() * scale) - stage.width();

          return {
            y: pos.y > 0 ? 0 : Math.abs(pos.y) > stageHeight ? -1 * stageHeight : pos.y,
            x: pos.x > 0 ? 0 : Math.abs(pos.x) > stageWidth ? -1 * stageWidth : pos.x,
          };
        };
        stage.setDraggable((newScale > 1));
        stage.dragBoundFunc((pos) => {
          return filterPos(pos, stage.scaleX());
        });

        stage.scale({ x: newScale, y: newScale });

        const newPos = {
          x:
              -(mousePointTo.x - stage.getPointerPosition().x / newScale) *
              newScale,
          y:
              -(mousePointTo.y - stage.getPointerPosition().y / newScale) *
              newScale
        };
        stage.position(filterPos(newPos, newScale));
        let markPoint = stage.find('.mark-group');
        if(markPoint != null){
          markPoint.forEach(function (item) {
            item.getChildren().forEach(function (it) {
              it.setAttrs({
                scaleX: 1 / stage.scaleX(),
                scaleY: 1 / stage.scaleY()
              })
            })
          })
        }
        stage.batchDraw();
      })
    },
    reqFetchFloorTree(){
      let query = {};
      fetchFloorTree(query).then(res =>{
        console.log("fetchFloorTree：",res)
        if(res.success){
          let floorTreeData = res.data;
        }
      })
    },
    pointSelect(floorId){
      const that = this;
      let query = floorId
      getPointManagementByFloorId(query).then(res => {
        if(res.success) {
          that.planeCode = res.data.coordinate;
          that.imageSrc = res.data.icon;
          that.pointManagements = res.data.managements;
        }
      }).catch(err => {
        that.planeCode = '{"attrs":{"width":1000,"height":500,"id":"my-stage"},"className":"Stage","children":[{"attrs":{"id":"my-layer"},"className":"Layer","children":[{"attrs":{"id":"my-floor-img"},"className":"Image"}]}]}';
        that.imageSrc = "";
        that.pointManagements = [];
      }).finally(function (){
        const {createStage, createLayer, createImage} = that;
        createStage();
        createLayer();
        createImage();
      })
    }
  }
}
</script>

<style scoped lang="scss">
.point-examine-container{
  width: 1030px;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .point-header{
    & > img {
      width: 1030px;
      height: auto;
    }
  }
  .point-body{
    width: 1000px;
    min-height: 500px;
    background: #FFFFFF;

    .operation{
      position: relative;
      z-index: 9999;
      .go-back{
        width: 132px;
        height: 32px;
        background: url('../../assets/images/point-examine/go-back.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        position: relative;
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 32px;
        text-shadow: 0px 0px 8px #3875F6;
        text-align: center;
        position: absolute;
        top: 10px;
        left: 10px;
        cursor: pointer;
      }
      .point-choose{
        position: absolute;
        top: 10px;
        right: 0;
      }
    }
  }
  .point-footer{
    & > img{
      width: 1030px;
      height: auto;
    }
  }
}
</style>
<style lang="scss">
.point-select{
  ::-webkit-scrollbar { width: 1px; -webkit-border-radius: 1px; }
  ::-webkit-scrollbar-track-piece { background-color: transparent !important;}
  ::-webkit-scrollbar-thumb{background-color: rgba(63, 144, 178, 1); -webkit-border-radius: 1px;}
}
</style>