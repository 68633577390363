<template>
  <div class="header">
    <div class="time">
      {{ date | formatTime }}
    </div>
    <div class="logout" @click="logout">退出登录<img src="../assets/images/index/logout.png" /></div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      date: new Date(),
    };
  },
  filters: {
    formatTime(dataStr) {
      var pattern = "YYYY年MM月DD日 HH:mm";
      return moment(dataStr).format(pattern);
    },
  },
  mounted() {
    let _this = this;
    this.timer = setInterval(() => {
      _this.date = new Date();
    }, 1000);
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  methods: {
    logout() {
      this.$confirm('是否退出系统, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$store.dispatch('LogOut').then(() => {
          this.$router.push({ path: '/login' })
        })
      })
    }
  },

}
</script>

<style lang="scss" scoped>
  .header {
    width: 1920px;
    height: 165px;
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -960px;
    z-index: 99;
    background: url('../assets/images/index/header-bg.png') no-repeat center;
    background-size: cover;
    background-position-y: bottom;
    .time {
      position: absolute;
      left: 51px;
      top: 22px;
      color: #fff;
      font-family: AlibabaPuHuiTiBold;
    }
    .logout {
      position: absolute;
      right: 53px;
      top: 19px;
      font-family: AlibabaPuHuiTiBold;
      font-size: 16px;
      font-weight: bold;
      color: #35FDFF;
      cursor: pointer;
      img {
        width: 27px;
        height: 22px;
        vertical-align: bottom;
        margin-left: 15px;
      }
    }
  }
</style>
