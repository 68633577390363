import Vue from "vue";
import VueRouter from "vue-router";
import Home from '../views/home'
import Login from '../views/login' //  登录

Vue.use(VueRouter);

const routes = [{
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/login.vue")
  }

]

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

// router.beforeEach((to, from, next) => {
//   let islogin = window.localStorage.token
//   console.log(window.localStorage.token, 'window.localStorage.token');
//   console.log(to.path, 'to.path');
//   if (to.path === '/login') {
//     if (islogin) {
//       next('/');
//     } else {
//       next();
//     }
//   } else if (islogin) {
//     next();
//   } else {
//     next("/login");
//   }
// })
// 重置路由

export function resetRouter () {
  const newRouter = createRouter()
  Router.matcher = newRouter.matcher
}

export default router;
