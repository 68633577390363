<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import { mapMutations } from 'vuex'
import {getStore, setStore} from '@/utils/store'
import {validatenull} from '@/utils/validate'
import {getUserInfo} from '@/api/login'


export default {
  data() {
     return {
     }
  },
  components: {
  },
  mounted() {
    // 实时刷新token
    // this.refreshToken()
    this.getUserInfo()
  },
  methods: {
    ...mapMutations(['SET_USER_INFO']),
    // 实时检测刷新token
    refreshToken() {
      this.refreshTime = setInterval(() => {
        const token = getStore({
          name: 'access_token',
          debug: true
        })
        if (validatenull(token)) {
          this.$router.push({
            path: '/login'
          })
          return
        }
        if (this.expires_in <= 1000 && !this.refreshLock) {
          this.refreshLock = true
          this.$store
            .dispatch('RefreshToken')
            .catch(() => {
              this.$router.push({
                path: '/login'
              })
              clearInterval(this.refreshTime)
            })
          this.refreshLock = false
        }
        this.$store.commit('SET_EXPIRES_IN', this.expires_in - 10)
      }, 10000)
    },
    getUserInfo() {
      const userInfo = getStore({ name: 'userInfo' })
      if (!userInfo) {
        // this.$api.get('/longwang-service-biz/user/info')
        // this.$api.get('/admin/user/info')
        getUserInfo().then(({ data }) => {
           this.SET_USER_INFO(data)
         })
      }
    }
  }
}
</script>
<style lang="scss">
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

*, *:before, *:after {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html,
body {
  height: 100%;
  width: 1920px;
  height: 1080px;
  background: #000;
}

body {
  font-size: 16px;
  transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  -o-transform-origin: 0 0;
}

@font-face {
    font-family: 'AlibabaPuHuiTi';
    src: url('./assets/font/阿里巴巴普惠体R.ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'AlibabaPuHuiTiBold';
    src: url('./assets/font/阿里巴巴普惠体B.ttf');
    font-weight: normal;
    font-style: normal;
}

#app {
  font-family: AlibabaPuHuiTi;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
  background: rgba(0, 0, 0, 1);
}

#nav {
  padding: 30px;
  font-size: 16px;
  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.container {
  position: relative;
  width: 1920px;
  margin: 0 auto;
  height: 1040px;
  margin: 0 auto;
  overflow: hidden;
  // padding-left: 16px;
  padding-right: 16px;
}

.card-title {
  font-size: 16px;
  color: #fff;
  text-align: center;
  position: absolute;
  top: 12px;
  left: 50%;
  transform: translateX(-50%);
  line-height: 16px;
  a {
    text-decoration: none;
    color: inherit;
  }
}

.flex {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.cursor {
  cursor: pointer;
}

.slick-slider {
  width: 90%;
  height: 100%;
  .slick-next, .slick-prev {
    opacity: 0 !important;
  }
  .slick-dots li button:before {
    color: rgba(102, 102, 102, 1);
    opacity: 1;
  }
  .slick-dots li.slick-active button:before {
    color: #30F6FD;
    opacity: 1;
  }
}

.center {
  .slick-slider {
    width: 100%;
  }
}

</style>
