import { render, staticRenderFns } from "./right.vue?vue&type=template&id=3b1c703e&scoped=true&"
import script from "./right.vue?vue&type=script&lang=js&"
export * from "./right.vue?vue&type=script&lang=js&"
import style0 from "./right.vue?vue&type=style&index=0&id=3b1c703e&lang=scss&scoped=true&"
import style1 from "./right.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b1c703e",
  null
  
)

export default component.exports