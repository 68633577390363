<template>
  <div class="right-three-dialog" v-if="visiable">
    <div class="title-bg">今日告警<div class="title-icon cursor" @click="hide"/></div>
    <div class="content">
      <div class="content-top">
        <div class="content-title mr-50">今日告警<div class="num">{{info.totalNum}}</div></div>
        <div class="content-title">已完结<div class="num">{{info.okNum}}</div></div>
      </div>
      <div class="menu">
        <div :class="['menu-item cursor', activeMenu === 'one' ? 'active' : '']" id="one" @click="changeMenu">待转派</div>
        <div :class="['menu-item cursor', activeMenu === 'two' ? 'active' : '']" id="two" @click="changeMenu">等待接警</div>
        <div :class="['menu-item cursor', activeMenu === 'three' ? 'active' : '']" id="three" @click="changeMenu">已接警</div>
        <!-- <div :class="['menu-item cursor', activeMenu === 'four' ? 'active' : '']" id="four" @click="changeMenu">处理中</div> -->
        <div :class="['menu-item cursor', activeMenu === 'five' ? 'active' : '']" id="five" @click="changeMenu">待处置</div>
        <div :class="['menu-item cursor', activeMenu === 'six' ? 'active' : '']" id="six" @click="changeMenu">已完结</div>
      </div>
      <div class="list" v-if="info.bigScreenTask.filter(item => item.nodeName === statusMap[activeMenu]).length > 0">
        <div class="list-item"
          v-for="item in info.bigScreenTask.filter(item => item.nodeName === statusMap[activeMenu])"
          :key="item.id"
          @click="(e) => showDetail(e, { ...item, show: true })"
        >
          <div class="item-title">
            <div class="content-title">{{item.title}}</div>
            <div class="item-status">{{item.nodeName}}</div>
          </div>
          <div class="item-address">{{item.taskPosition}}</div>
          <div class="item-time">{{item.updateTime}}</div>
        </div>
      </div>
      <div class="emptyWrapper" v-else>
        <img class="empty" src="../../assets/images/index/empty.png"/>暂无数据..
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      visiable: false,
      activeMenu: 'one',
      statusMap: {
        'one': '待转派',
        'two': '等待接警',
        'three': '已接警',
        // 'four': '处理中',
        'five': '待处置',
        'six': '已完结',
      }
    };
  },
  props: ['showDetail', 'info'],
  mounted() {
  },
  methods: {
    show(e, status) {
      e.stopPropagation()
      this.visiable = true
      this.activeMenu = (status === 6) ? 'six' : 'one'
    },
    hide() {
      this.visiable = false
    },
    changeMenu(e) {
      this.activeMenu = e.target.id
    }
  }
}
</script>

<style lang="scss" scoped>
  .right-three-dialog {
    background: url('../../assets/images/index/right-two-dialog-bg.png') no-repeat;
    background-size: cover;
    position: absolute;
    width: 528px;
    height: 652px;
    right: 439px;
    bottom: 55px;
    z-index: 999999;
    padding-top: 24px;
    .title-bg {
      background: url('../../assets/images/index/right-two-dialog-title-bg.png') no-repeat;
      background-size: cover;
      width: 509px;
      height: 33px;
      .title-icon {
        background: url('../../assets/images/index/right-two-dialog-icon-bg.png') no-repeat;
        background-size: cover;
        width: 15px;
        height: 15px;
      }
    }
    .content {
      .content-top {
        font-size: 14px;
        font-family: AlibabaPuHuiTi;
        font-weight: 400;
        display: flex;
        align-items: center;
        color: #FFFFFF;
        line-height: 30px;
        margin-top: 20px;
        margin-bottom: 19px;
        padding-left: 20px;
        .content-title {
          display: flex;
          align-items: center;
          line-height: 1;
          flex: 1;
        }
        .num {
          font-size: 18px;
          font-family: AlibabaPuHuiTi;
          font-weight: 400;
          color: #FDA52F;
          margin-left: 11px;
        }
      }
      .menu {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 19px;
        margin-bottom: 24px;
        .menu-item {
          font-size: 16px;
          font-family: AlibabaPuHuiTi;
          font-weight: 300;
          color: #fff;
          padding-bottom: 5px;
          &.active {
            color: #19D6FF;
            border-bottom: 2px solid;
          }
        }
      }
      .emptyWrapper {
        height: 470px;
        padding-top: 150px;
        .empty {
          width: 31px;
          height: 31px;
          vertical-align: middle;
        }
      }
      .list {
        padding: 0 19px;
        height: 470px;
        overflow-y: scroll;
        .list-item {
          width: 100%;
          margin-bottom: 10px;
          text-align: left;
          padding: 13px 17px 0 17px;
          border-top: 1px solid rgba(255, 255, 255, 0.25);
          .item-title {
            display: flex;
            justify-content: space-between;
            .item-status {
              color: #02E8FE;
              width: fit-content;
            }
            .content-title {
              flex: 1;
              margin-right: 10px;
            }
          }
          .item-address {
            font-size: 14px;
            font-family: AlibabaPuHuiTiBold;
            font-weight: 400;
            color: #C6C6C6;
            margin-top: 11px;
            margin-bottom: 8px;
          }
          .item-time {
            font-size: 14px;
            font-family: AlibabaPuHuiTiBold;
            font-weight: 400;
            color: #C6C6C6;
          }
        }
      }
    }
  }
</style>
