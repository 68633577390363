<template>
  <div class="bottom">
    <div class="title-bg">任务完成情况<div class="title-icon"/></div>
    <div class="content">
      <div class="chart" id="processChart" />
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { getStore } from '@/utils/store'
export default {
  data() {
    return {
      chart: null,
      option: null,
      deviceInfo: {
        operationVOList: [],
        sysRunList: []
      },
    };
  },
  props: ['groupParkId'],
  watch: {
    groupParkId() {
      this.getData()
    }
  },
  mounted() {
    const { getData } = this
    getData()
    const timer = setInterval(() => { getData(); }, 1000 * 60);
    this.$once('hook:beforeDestroy',function () {
      clearInterval(timer);
    });
  },
  methods: {
    getData() {
      this.$api.get('/longwang-service-biz/bigScreenDisplay/deviceInfo?groupParkId=' + (this.groupParkId || ""))
       .then(({ data }) => {
         this.deviceInfo = data
         this.drawBar()
       })
    },
    drawBar() {
      this.chart = this.$echarts.init(document.getElementById('processChart'))
      this.option = {
        grid: {
            top: '10%',
            left: '0%',
            right: '2%',
            bottom: '37%',
        },
        tooltip: {
            show: false,
        },
        xAxis: {
            interval: 0,
            offset: 5,
            data: this.deviceInfo.operationVOList.map(item => item.taskName),
            axisLine: {
                lineStyle: {
                    color: 'transparent', //底部边框颜色
                },
            },
            axisLabel: {
                interval: 0,
                textStyle: {
                },
                formatter: (value) => {
                  const info = this.deviceInfo.operationVOList.find(item => item.taskName === value) || {}
                  const arr = [`{a|${info.goodNum}/}`, `{b|${info.totalNum}}`, '\n', `{c|${value}}`]
                  return arr.join('');
                },
                rich: {
                  a: {
                    fontSize: 18,
                    fontFamily: 'DIN',
                    fontWeight: 'bold',
                    color: '#FFFFFF',
                    lineHeight: 25,
                  },
                  b: {
                    fontSize: 18,
                    color: '#FFFFFF',
                    fontFamily: 'DIN-Bold',
                    lineHeight: 25,
                  },
                  c: {
                    color: '#fff', //底部文字颜色
                    fontSize: 12,
                    paddingTop: 5,
                  }
                }
            },
        },
        yAxis: [
            {
                show: false,
                max: 1,
                min: 0
            },
        ],
        series: [
            {
                type: 'bar',
                barWidth: 21,
                itemStyle: {
                    normal: {
                      borderRadius: 20,
                      color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                          {
                              offset: 0,
                              color: 'rgba(2, 106, 253, 1)', //渐变1
                          },
                          {
                              offset: 1,
                              color: 'rgba(0, 233, 253, 1)', //渐变2
                          },
                      ]),
                    },
                },
                animation: true,
                animationThreshold: 6000,
                animationDuration: 6000,
                data: this.deviceInfo.operationVOList.map(item => (item.totalNum === 0 ? 0 : (item.goodNum / item.totalNum))),
                z: 0,
                zlevel: 0,
            },
            {
                type: 'pictorialBar',
                barWidth: 21,
                itemStyle: {
                    normal: {
                      borderRadius: 20,
                      color: 'rgba(102, 102, 102, 0.5)', //数据的间隔颜色
                    },
                },
                symbolRepeat: 'fixed',
                symbolMargin: 3,
                symbol: 'rect',
                symbolSize: [21, 4],
                symbolPosition: 'end',
                symbolOffset: [0, 0],
                animation: true,
                animationThreshold: 3000,
                animationDuration: 3000,
                data: this.deviceInfo.operationVOList.map(item => item.totalNum === 0 ? 0 : (item.goodNum / item.totalNum)),
                z: 1,
                zlevel: 0,
            },
        ],
    }
      this.chart.setOption(this.option)
    },
  }
}
</script>

<style lang="scss" scoped>
  .bottom {
    position: absolute;
    left: 479px;
    top: 817px;
    width: 1000px;
    height: 250px;
    color: #fff;
    pointer-events: none;
    .content {
      .chart {
        margin-top: 30px;
        width: 920px;
        height: 180px;
      }
      .numList {
        width: 880px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: absolute;
        bottom: 40px;
        .item {
          margin-left: 27px;
          .title {
            font-family: AlibabaPuHuiTi;
            font-weight: 400;
            color: #FFFFFF;
          }
          .num {
            font-size: 18px;
            font-family: DIN;
            font-weight: bold;
            color: #FFFFFF;
          }
          .total {
            font-size: 16px;
            font-family: DIN-Bold;
          }
        }
      }
    }
  }
</style>
