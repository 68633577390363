<template>
  <div class="right-three-dialog" v-if="visiable">
    <div class="title-bg">重点部位<div class="title-icon cursor" @click="hide"/></div>
    <div class="content">
      <div class="content-top">
        <div class="content-title mr-50">今日重点部位任务<div class="num">{{info.keyPartsAlertNum}}</div></div>
        <div class="content-title">已完结<div class="num">{{info.exNum}}</div></div>
      </div>
      <div class="list" v-if="info.keyParts.length > 0">
        <div
          :class="['list-item', (item.latestReportAbnormal || item.nodeName === '待提交') ? 'active' : '']"
          v-for="item in info.keyParts"
          :key="item.id"
          @click="(e) => (item.latestReportAbnormal || item.nodeName === '待提交') && showDetail(e, { ...item, show: true })"
        >
          <div class="item-title">
            <div class="content-title">{{item.name}}</div>
            <div class="item-status-error">{{item.nodeName}}</div>
            <!-- <div class="item-status-error" v-if="item.latestReportAbnormal">异常</div>
            <div class="item-status" v-else>无异常</div> -->
          </div>
          <div class="item-address">{{item.address}}</div>
          <div class="item-time">
            <div class="item-desc one">{{item.useFunction}}</div>
            <div class="item-desc two">{{item.riskPoint}}</div>
          </div>
        </div>
      </div>
      <div class="emptyWrapper" v-else>
        <img class="empty" src="../../assets/images/index/empty.png"/>暂无数据..
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      visiable: false,
      activeMenu: 'one',
      list: [
        {id: 1, title: '东食堂', address: '阿里巴巴/西溪园区B区/东食堂/1F', room: '厨房', desc: '大功率用电/明火', status: '无异常' },
        {id: 2, title: '东食堂', address: '阿里巴巴/西溪园区B区/东食堂/1F', room: '厨房', desc: '大功率用电/明火', status: '无异常' },
        {id: 3, title: '热成像告警', address: '阿里巴巴/西溪园区B区/东食堂/1F', room: '厨房', desc: '大功率用电/明火', status: '异常' },
        {id: 4, title: '东食堂', address: '阿里巴巴/西溪园区B区/东食堂/1F', room: '厨房', desc: '大功率用电/明火', status: '无异常' },
        {id: 5, title: '热成像告警', address: '阿里巴巴/西溪园区B区/东食堂/1F', room: '厨房', desc: '大功率用电/明火', status: '无异常' },
        {id: 6, title: '东食堂', address: '阿里巴巴/西溪园区B区/东食堂/1F', room: '厨房', desc: '大功率用电/明火', status: '无异常' },
        {id: 7, title: '东食堂', address: '阿里巴巴/西溪园区B区/东食堂/1F', room: '厨房', desc: '大功率用电/明火', status: '无异常' },
        {id: 8, title: '东食堂', address: '阿里巴巴/西溪园区B区/东食堂/1F', room: '厨房', desc: '大功率用电/明火', status: '无异常' },
      ],
    };
  },
  props: ['showDetail', 'info'],
  mounted() {
  },
  methods: {
    show() {
      this.visiable = true
    },
    hide() {
      this.visiable = false
    },
    changeMenu(e) {
      this.activeMenu = e.target.id
    }
  }
}
</script>

<style lang="scss" scoped>
  .right-three-dialog {
    background: url('../../assets/images/index/right-two-dialog-bg.png') no-repeat;
    background-size: cover;
    position: absolute;
    width: 528px;
    height: 652px;
    right: 439px;
    bottom: 55px;
    z-index: 999;
    padding-top: 24px;
    .title-bg {
      background: url('../../assets/images/index/right-two-dialog-title-bg.png') no-repeat;
      background-size: cover;
      width: 509px;
      height: 33px;
      .title-icon {
        background: url('../../assets/images/index/right-two-dialog-icon-bg.png') no-repeat;
        background-size: cover;
        width: 15px;
        height: 15px;
      }
    }
    .content {
      .content-top {
        font-size: 14px;
        font-family: AlibabaPuHuiTi;
        font-weight: 400;
        display: flex;
        align-items: center;
        color: #FFFFFF;
        line-height: 30px;
        margin-top: 20px;
        margin-bottom: 19px;
        padding-left: 25px;
        .content-title {
          display: flex;
          align-items: center;
          line-height: 1;
        }
        .num {
          font-size: 18px;
          font-family: AlibabaPuHuiTi;
          font-weight: 400;
          color: #FDA52F;
          margin-left: 11px;
        }
      }
      .emptyWrapper {
        height: 470px;
        padding-top: 150px;
        .empty {
          width: 31px;
          height: 31px;
          vertical-align: middle;
        }
      }
      .list {
        height: 520px;
        overflow-y: scroll;
        .list-item {
          width: 100%;
          text-align: left;
          padding: 13px 25px 0 25px;
          border-top: 1px solid rgba(255, 255, 255, 0.25);
          .item-title {
            display: flex;
            justify-content: space-between;
            .item-status {
              color: #02E8FE;
            }
            .item-status-error {
              color: rgba(255, 30, 30, 1);
              width: fit-content;
            }
            .content-title {
              margin-right: 10px;
              flex: 1;
            }

          }
          .item-time {
            display: flex;
            justify-content: space-between;
            .item-desc {
              font-size: 14px;
              font-family: AlibabaPuHuiTi;
              font-weight: 400;
              color: #E2E2E2;
              margin-bottom: 5px;
              &.one {
                width: fit-content;
                margin-right: 10px;
              }
              &.two {
                flex: 1;

              }
            }
          }
          .item-address {
            font-size: 14px;
            font-family: AlibabaPuHuiTi;
            font-weight: 400;
            color: #C6C6C6;
            margin-top: 11px;
            margin-bottom: 8px;
          }
          &.active {
            background: rgba(171, 4, 4, 0.32);
            cursor: pointer;
          }
        }
      }
    }
  }
</style>
