<template>
  <div class="center">
    <div class="chart" id="mapChart" v-if="!isQuanAdmin" />
    <div class="chart-menu" v-if="!isQuanAdmin && parkInfo.length">
      <div class="arrow-left" v-if="parkInfo.length > 1">
        <img src="../assets/images/index/left.png" />
      </div>
      <div class="menu-content" v-if="parkInfo.length >= 3">
        <VueSlickCarousel v-bind="settings">
          <div
            :class="[
              'menu-item',
              'cursor',
              groupParkId === item.id ? 'active' : '',
            ]"
            v-for="item in parkInfo"
            :key="item.id"
            :id="item.id"
            @click="() => changePark(item)"
          >
            {{ item.name }}
          </div>
        </VueSlickCarousel>
      </div>
      <div class="menu-content" v-else>
        <div
          :class="[
            'menu-item',
            'cursor',
            groupParkId === item.id ? 'active' : '',
          ]"
          v-for="item in parkInfo"
          :key="item.id"
          :id="item.id"
          @click="() => changePark(item)"
        >
          {{ item.name }}
        </div>
      </div>
      <div class="arrow-right" v-if="parkInfo.length > 1">
        <img src="../assets/images/index/right.png" />
      </div>
    </div>
    <div class="content">
      <div class="menu" v-if="(isGroupAdmin || (isAdmin && isQuanAdmin)) && parkInfo.length">
        <div class="arrow-left" v-if="parkInfo.length > 1">
          <img src="../assets/images/index/left.png" />
        </div>
        <div class="menu-content" v-if="parkInfo.length >= 3">
          <VueSlickCarousel v-bind="settings">
            <div v-if="isAdmin && isQuanAdmin" class="menu-item cursor" @click="() => changePark({})">
              全国
            </div>
            <div
              :class="[
                'menu-item',
                'cursor',
                groupParkId === item.id ? 'active' : '',
              ]"
              v-for="item in parkInfo"
              :key="item.id"
              :id="item.id"
              @click="() => changePark(item)"
            >
              {{ item.name }}
            </div>
          </VueSlickCarousel>
        </div>
        <div class="menu-content" v-else>
          <div class="menu-item cursor" v-if="isAdmin && isQuanAdmin" @click="() => changePark({})">
            全国
          </div>
          <div
            :class="[
              'menu-item',
              'cursor',
              groupParkId === item.id ? 'active' : '',
            ]"
            v-for="item in parkInfo"
            :key="item.id"
            :id="item.id"
            @click="() => changePark(item)"
          >
            {{ item.name }}
          </div>
        </div>
        <div class="arrow-right" v-if="parkInfo.length > 1">
          <img src="../assets/images/index/right.png" />
        </div>
      </div>
      <div class="desc" v-if="currentInfo.name && isQuanAdmin">
        <div class="desc-title">
          <img src="../assets/images/index/weizhi.png" />{{ currentInfo.name }}
        </div>
        <div class="desc-content">
          <div class="item">
            <div class="icon">
              <img src="../assets/images/index/icon14.png" />
            </div>
            <div class="title">负责人：{{ parkOwnerInfo.responsibleName }}</div>
          </div>
          <div class="line" />
          <div class="item">
            <div class="icon">
              <img src="../assets/images/index/icon15.png" />
            </div>
            <div class="title">
              联系电话：{{ parkOwnerInfo.responsiblePhone }}
            </div>
          </div>
          <div class="line" />
          <div class="item">
            <div class="icon">
              <img src="../assets/images/index/icon16.png" />
            </div>
            <div class="title">
              今日值班：{{ parkOwnerInfo.daytimeWatchman }}
            </div>
          </div>
        </div>
      </div>
      <div class="map-contain" v-if="isQuanAdmin">
        <div class="map-content map" id="map"></div>
      </div>
    </div>
    <div class="desc1" v-if="desc1Visable">
      <div class="desc1-top">
        <div class="top-left">
          <div class="desc1-icon">
            <img src="../assets/images/index/bangongshi.png" />
          </div>
          <div class="title">{{detailInfo.taskTitle}}</div>
        </div>
        <div class="top-right">
          <div class="desc1-icon cursor" @click="hideDes1">
            <img src="../assets/images/index/right-two-dialog-icon-bg.png" />
          </div>
        </div>
      </div>

      <div class="desc1-img">
        <div class="arrow-left1">
          <img src="../assets/images/index/left.png" />
        </div>
        <VueSlickCarousel
          :arrows="true"
          :dots="true"
          v-bind="settings1"
          v-if="detailInfo.taskPictures.length"
        >
          <img
            v-for="item in detailInfo.taskPictures"
            :key="item"
            :src="item"
            alt=""
            width="304"
            height="150"
          />
        </VueSlickCarousel>
        <div class="arrow-right1">
          <img src="../assets/images/index/right.png" />
        </div>
      </div>

      <div class="desc1-item" style="margin-top:30px">
        <img class="item-icon" src="../assets/images/index/icon2.png" />
        <span class="item-label">位置：</span>{{detailInfo.taskPosition}}
      </div>
      <div class="desc1-item">
        <img class="item-icon" src="../assets/images/index/icon3.png" />
        <span class="item-label">时间：</span>{{detailInfo.createTimes}}
      </div>
      <div class="desc1-item">
        <img class="item-icon" src="../assets/images/index/icon.png" />
        <span class="item-label">告警原因：</span>{{detailInfo.taskContent}}
      </div>
      <div class="desc1-item">
        <img class="item-icon" src="../assets/images/index/icon6.png" />
        <span class="item-label">设备编号：</span>{{detailInfo.deviceId}}
      </div>
      <div class="desc1-item">
        <img class="item-icon" src="../assets/images/index/icon7.png" />
        <span class="item-label">设备名称：</span>{{detailInfo.deviceName}}
      </div>
      <div class="desc1-item">
        <img class="item-icon" src="../assets/images/index/icon8.png" />
        <span class="item-label">设备回路号：</span>{{detailInfo.deviceCircuitNumber}}
      </div>
    </div>
    <div class="desc2" v-if="desc2Visable">
      <div class="desc2-top">
        <div class="top-left">
          <div class="desc2-icon">
            <img src="../assets/images/index/weizhi.png" />
          </div>
          <div class="title">{{ currentInfo.name }}</div>
        </div>
        <div class="top-right">
          <div class="desc2-icon cursor" @click="hideDes2">
            <img src="../assets/images/index/right-two-dialog-icon-bg.png" />
          </div>
        </div>
      </div>
      <div class="desc-bottom">
        <div class="item">
          <div class="icon"><img src="../assets/images/index/icon9.png" /></div>
          <div class="title">负责人：{{ parkOwnerInfo.responsibleName }}</div>
        </div>
        <div class="item">
          <div class="icon">
            <img src="../assets/images/index/icon10.png" />
          </div>
          <div class="title">
            联系电话：{{ parkOwnerInfo.responsiblePhone }}
          </div>
        </div>
        <div class="item">
          <div class="icon">
            <img src="../assets/images/index/icon11.png" />
          </div>
          <div class="title">今日值班：{{ parkOwnerInfo.daytimeWatchman }}</div>
        </div>
      </div>
    </div>
    <global-web-socket
      @showModel="updateVisable"
      :sendData="desc1Visable"
      uri="/longwang-service-biz/ws/info"
      v-if="true"
    />
  </div>
</template>

<script>
import moment from "moment";
import VueSlickCarousel from "vue-slick-carousel";
import { getStore } from "@/utils/store";
import chinaJson from "./china.json";
import getMapOption from "./mapOption.js";
import GlobalWebSocket from "@/components/ws/GlobalWebSocket.vue";
const redpng = require("../assets/images/index/red.png");
const redIconpng = require("../assets/images/index/redIcon.png");
const img1Png = require("../assets/images/index/title-bg3.png");
const imgIcon1Png = require("../assets/images/index/icon17.png");
const imgPng = require("../assets/images/index/icon5.png");
const img5Png = require("../assets/images/index/icon55.png");
// const imgIconPng = require("../assets/images/index/icon4.png");
const imgIconPng = require("../assets/images/index/blue.png");
// const imgIconActivePng = require("../assets/images/index/icon12.png");
const imgIconActivePng = require("../assets/images/index/orange.png");
const imgActivePng = require("../assets/images/index/icon13.png");

export default {
  data() {
    return {
      desc1Visable: false,
      desc2Visable: false,
      activeName: "",
      iconMark: null,
      textMark: null,
      pname: '',
      parkOwnerInfo: {},
      currentInfo: {},
      parkInfo: [],
      settings: {
        dots: false,
        infinite: true,
        initialSlide: 0,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        swipeToSlide: true,
      },
      settings1:{
        "dots": true,
        "dotsClass": "slick-dots custom-dot-class",
        "edgeFriction": 0.35,
        "infinite": true,
        "speed": 500,
        "slidesToShow": 1,
        "slidesToScroll": 1
      },
      detailInfo: {
        taskPictures: [{}, {}, {}],
      },
    };
  },
  components: {
    VueSlickCarousel,
    GlobalWebSocket,
  },
  props: ["groupParkId", "isQuanAdmin", "isGroupAdmin", "changeGroupParkId", "isAdmin"],
  mounted() {
    const { renderMap, getParkInfo } = this
    renderMap()
    if (this.isQuanAdmin) getParkInfo()
    const timer = setInterval(() => {
      if (this.isQuanAdmin && !this.isAdmin) getParkInfo();
    }, 1000 * 10);
    this.$once('hook:beforeDestroy',function () {
      clearInterval(timer);
    });
  },
  watch: {
    // type变化则全面刷新
    groupParkId: {
      immediate: true,
      handler() {
        if (this.groupParkId) {
          this.getParkOwnerInfo();
          // this.getParkInfo()
        }
      },
    },
    isQuanAdmin() {
      // this.getParkInfo()
      setTimeout(() => {
        this.renderMap()
      }, 1000)
    }
  },
  methods: {
    // 更新ws弹窗
    updateVisable(data) {
      //触发子组件城市选择-选择城市的事件

      var num = 10;
      if (!this.isQuanAdmin) this.desc1Visable = data.visiable;
      this.detailInfo = JSON.parse(data.obj); //改变了父组件的值
      const { jsonParkPosition } = this.detailInfo;
      const { location, pname } = jsonParkPosition || {};
      const { lat, lng } = location || {};
      const img = `image://${imgPng}`;
      const imgRed = `image://${redpng}`;
      const imgIcon = `image://${imgIconPng}`;
      const imgRedIcon = `image://${redIconpng}`;
      const imgIconActive = `image://${imgIconActivePng}`;
      const parkData = this.parkInfo.length > 0 ? this.parkInfo : (getStore({ name: "userInfo" }) || {}).parkData
      this.pname = pname
      this.chart.setOption(
        getMapOption({
          data: parkData.map((item) => item.province),
          pname: pname,
          imgRed,
          imgRedIcon,
          img,
          imgIcon,
          imgIconActive,
        })
      );
      var setTiem = setInterval(() => {
        num--;
        if (num == 0) {
          this.desc1Visable=false;
          this.pname = ''
          this.chart.setOption(
            getMapOption({
              data: parkData.map((item) => item.province),
              img,
              imgIcon,
              imgIconActive,
            })
          );
          clearInterval(setTiem);
        }
      }, 1000);
    },
    showMark({ lat, lng }) {
      if (this.iconMark) this.map.remove(this.iconMark);
      if (this.textMark) this.map.remove(this.textMark);
      if (!(lng && lat)) return;
      this.iconMark = new AMap.Marker({
        position: [lng, lat],
        clickable: true,
        topWhenClick: true,
        icon: imgIcon1Png,
        offset: new AMap.Pixel(-26, -25),
      });
      this.textMark = new AMap.Marker({
        position: [lng, lat],
        clickable: true,
        topWhenClick: true,
        anchor: "top-center",
        content: `<div
          ><div style="color: #fff;width: 150px; height: 29px;line-height: 29px;font-weight: bold; font-size: 14px; background-image: url('${img5Png}');background-repeat: no-repeat;background-size: contain;">${this.currentInfo.name}</div></div>`,
        offset: new AMap.Pixel(-10, -50),
      });
      this.map.add(this.iconMark);
      this.map.add(this.textMark);
      this.map.setZoomAndCenter(13, [lng, lat]);
      // this.map.setFitView();
    },
    renderMap() {
      if (this.chart) this.chart.dispose()
      if (this.isQuanAdmin) {
        // 获取到作为地图容器的DOM元素
        const container = document.querySelector(".map");
        // 创建地图实例
        this.map = new AMap.Map(container, {
          disableSocket: true,
          showLabel: true,
        });
        if (this.currentInfo) {
          const positionInfo = this.currentInfo.jsonParkPosition || {};
          const { location } = positionInfo;
          const { lat, lng } = location || {};
          this.showMark({ lat, lng });
        }
      } else {
        this.chart = this.$echarts.init(document.getElementById("mapChart"));
        this.$echarts.registerMap("china", chinaJson);

        // 全国管理员
        const img = `image://${imgPng}`;
        const imgIcon = `image://${imgIconPng}`;
        const imgIconActive = `image://${imgIconActivePng}`;
        const imgActive = `image://${imgActivePng}`;
        const imgRed = `image://${redpng}`;
        const imgRedIcon = `image://${redIconpng}`;
        const parkInfo = getStore({ name: "userInfo" }).parkData || []
        this.chart.setOption(
          getMapOption({
            data: parkInfo.map((item) => item.province),
            pname: this.pname,
            img,
            imgRed,
            imgRedIcon,
            imgIcon,
            imgIconActive,
          })
        );
        this.chart.on("click", (e) => {
          this.parkInfo = parkInfo.filter((item) => item.province === (e.name + '省'))
          if (e.seriesType === "scatter") {
            this.chart.setOption(
              getMapOption({
                data: parkInfo.map((item) => item.province),
                img,
                imgIcon,
                imgIconActive,
                imgActive,
                activeName: e.name,
              })
            );
            // this.currentInfo =
            //   this.parkInfo.find(
            //     (item) => (item.province || "").indexOf(e.name) > -1
            //   ) || {};
            // this.changeGroupParkId(this.currentInfo.id);
            // this.desc2Visable = true;
          }
        });
      }
    },
    changePark(info) {
      if (info.id) {
        this.currentInfo = info;
        this.changeGroupParkId(info.id);
        const positionInfo = this.currentInfo.jsonParkPosition || {};
        const { location } = positionInfo;
        const { lat, lng } = location || {};
        if (this.map) {
          this.showMark({ lat, lng });
        }
      } else {
        this.changeGroupParkId();
        this.parkInfo = []
      }
    },
    hideDes1() {
      this.desc1Visable = false;
    },
    hideDes2() {
      this.desc2Visable = false;
    },
    getParkOwnerInfo() {
      this.$api
        .get(
          "/longwang-service-biz/bigScreenDisplay/parkOwnerInfo?groupParkId=" +
            this.groupParkId
        )
        .then(({ data }) => {
          this.parkOwnerInfo = data[0] || {};
        });
    },
    getParkInfo() {
      this.$api
        .get(
          "/longwang-service-biz/bigScreenDisplay/parkInfo"
        )
        .then(({ data }) => {
          this.parkInfo = data || [];
          if (this.isQuanAdmin && !this.currentInfo.id) {
            this.changePark(this.parkInfo.find(item => item.id === this.groupParkId) || {})
          };
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.center {
  width: 1190px;
  height: 800px;
  left: 390px;
  top: 100px;
  z-index: 999;
  position: absolute;
  .chart-menu {
    left: 110px;
    top: 0;
    z-index: 9999;
    position: absolute;
    display: flex;
    justify-content: space-between;
    width: 76%;
    align-items: center;
    .menu-content {
      height: 54px;
      width: 700px;
      flex: 1;
      display: flex;
      .menu-item {
        height: 54px;
        line-height: 54px;
        width: 218px;
        background: url("../assets/images/index/title-bg1.png") no-repeat
          center;
        background-size: contain;
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #ffffff;
        margin: 0 auto;
        &.active {
          background: url("../assets/images/index/title-bg2.png") no-repeat
            center;
        }
      }
    }

    .arrow-left,
    .arrow-right {
      cursor: pointer;
      // margin-right: 24px;
      img {
        width: 31.1px;
        height: 31.1px;
      }
    }
    .arrow-left {
      // margin-right: 24px;
    }
    .arrow-right {
      // margin-left: 24px;
    }
  }
  .content {
    width: 964px;
    height: 511px;
    margin-left: 80px;
    .menu {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      .menu-content {
        height: 54px;
        width: 700px;
        flex: 1;
        display: flex;
        .menu-item {
          height: 54px;
          line-height: 54px;
          width: 218px;
          background: url("../assets/images/index/title-bg1.png") no-repeat
            center;
          background-size: contain;
          font-size: 20px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #ffffff;
          margin: 0 auto;
          &.active {
            background: url("../assets/images/index/title-bg2.png") no-repeat
              center;
          }
        }
      }

      .arrow-left,
      .arrow-right {
        cursor: pointer;
        // margin-right: 24px;
        img {
          width: 31.1px;
          height: 31.1px;
        }
      }
      .arrow-left {
        // margin-right: 24px;
      }
      .arrow-right {
        // margin-left: 24px;
      }
    }
    .desc {
      width: 875px;
      height: 80px;
      margin: 0 auto;
      background: url("../assets/images/index/desc-bg.png") no-repeat center;
      background-size: contain;
      padding: 14px 34px;
      margin-top: 13px;
      .desc-title {
        text-align: left;
        color: rgba(235, 153, 45, 1);
        font-size: 17px;
        height: 17px;
        line-height: 17px;
        margin-bottom: 5px;
        img {
          width: 14px;
          height: 17px;
          margin-right: 5px;
          vertical-align: bottom;
        }
      }
      .desc-content {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        font-size: 16px;
        font-family: PingFangSC-Light;
        font-weight: 300;
        color: #ffffff;
        margin-left: 14px;
        .line {
          width: 2px;
          height: 18px;
          border: 1px solid #62d1fa;
        }
        .item {
          display: flex;
          align-items: flex-start;
          margin-top: 10px;
          min-width: 220px;
          .icon {
            width: 14px;
            height: 14px;
            margin-right: 5px;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
    .map-contain {
      width: 964px;
      height: 511px;
      margin: 0 auto;
      padding: 30px;
      margin-top: 18px;
      background: url("../assets/images/index/map-bg.png") no-repeat center;
      background-size: contain;
      .map-content {
        width: 100%;
        height: 100%;
      }
    }
  }
  .chart {
    width: 100%;
    height: 100%;
  }
  .desc1 {
    width: 353px;
    padding: 25px;
    position: absolute;
    text-align: left;
    background: url("../assets/images/index/desc1-bg.png");
    background-repeat: no-repeat;
    top: 200px;
    left: 100px;
    .desc1-top {
      display: flex;
      justify-content: space-between;
      .top-left {
        display: flex;
      }
      .title {
        font-size: 14px;
        font-family: AlibabaPuHuiTi;
        color: #fda530;
      }
      .desc1-icon {
        width: 15px;
        height: 14px;
        margin-right: 5px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .desc1-img {
      width: 100%;
      height: 154px;
      margin-top: 13px;
      margin-bottom: 15px;
      position: relative;
      display: flex;
      justify-content: space-around;
      align-items: center;
    }
    .desc1-item {
      display: flex;
      align-items: center;
      margin-bottom: 5px;
      .item-icon {
        width: 13px;
        height: 14px;
        margin-right: 10px;
      }
      .item-label {
        color: #0975df;
        font-family: PingFangSC;
        flex-shrink: 0;
      }
      font-size: 14px;
      font-family: AlibabaPuHuiTi;
      color: #fff;
    }
  }
  .desc2 {
    width: 568px;
    height: 82px;
    padding: 15px;
    position: absolute;
    text-align: left;
    background: url("../assets/images/index/desc2-bg.png") no-repeat center;
    background-size: contain;
    top: 100px;
    left: 240px;
    .desc-bottom {
      display: flex;
      align-items: center;
      white-space: nowrap;
      justify-content: space-between;
      font-size: 14px;
      font-family: AlibabaPuHuiTi;
      font-weight: 300;
      color: #ffffff;
      .item {
        display: flex;
        align-items: center;
        margin-top: 10px;
        .icon {
          width: 14px;
          height: 14px;
          margin-right: 5px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .desc2-top {
      display: flex;
      justify-content: space-between;
      .top-left {
        display: flex;
      }
      .top-right {
        .desc2-icon {
          width: 14px;
          height: 14px;
        }
      }
      .title {
        font-size: 14px;
        font-family: AlibabaPuHuiTi;
        color: #fda530;
      }
      .desc2-icon {
        width: 11px;
        height: 14px;
        margin-right: 5px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .arrow-left1,
  .arrow-right1 {
    cursor: pointer;
    img {
      width: 15px;
      height: 20px;
    }
  }
}
</style>
