<template>
  <div class="left">
    <div class="left-one" v-if="!isQuanAdmin">
      <div class="title-bg">
        接入数
        <div class="title-icon" />
      </div>
      <div class="content">
        <div class="content-item">
          <div class="content-img one-img">
            <img src="../assets/images/index/jrs.png" />
          </div>
          <div class="content-num">{{ baseInfo.totalNum }}</div>
          <div class="content-title">总接入数</div>
        </div>
        <div class="content-item">
          <div class="content-img two-img">
            <img src="../assets/images/index/zyyqjrs.png" />
          </div>
          <div class="content-num">{{ baseInfo.selfNum }}</div>
          <div class="content-title">自有园区接入数</div>
        </div>
        <div class="content-item">
          <div class="content-img three-img">
            <img src="../assets/images/index/zljrs.png" />
          </div>
          <div class="content-num">{{ baseInfo.leaseNum }}</div>
          <div class="content-title">租赁接入数</div>
        </div>
      </div>
    </div>
    <div class="left-one-one" v-else>
      <div class="title-bg">
        公告
        <div class="title-icon" />
      </div>
      <div class="content" v-if="announcementInfo.length">
        <div class="arrow-left" v-if="announcementInfo.length > 1">
          <img src="../assets/images/index/left.png" />
        </div>
        <VueSlickCarousel
          :arrows="true"
          :dots="false"
          v-bind="settings"
          v-if="announcementInfo.length"
        >
          <div class="info" v-for="item in announcementInfo" :key="item.id" v-html="item.content"></div>
        </VueSlickCarousel>
        <div class="arrow-right" v-if="announcementInfo.length > 1">
          <img src="../assets/images/index/right.png" />
        </div>
      </div>
      <div class="content" v-else>
        <div class="info">暂无公告</div>
      </div>
    </div>
    <div class="left-two" v-if="!isQuanAdmin">
      <div class="title-bg">
        健康情况排名
        <div class="title-icon" />
      </div>
      <div class="content" v-infinite-scroll="load">
        <vue-seamless-scroll
          :data="healthInfo"
          ref="seamlessScroll"
          :class-option="defaultOption"
        >
          <div v-for="(item, idx) in healthInfo" :key="item.id">
            <div class="item">
              <div class="item-title">{{ item.parkName }}</div>
              <div 
                class="item-status"
                style="color: #3BAEFC"
                 v-if="item.scoreStage === '完美'">
                {{ item.scoreStage }}
              </div>
              <div class="item-status" v-if="item.scoreStage === '健康'">
                {{ item.scoreStage }}
              </div>
              <div
                class="item-status"
                style="color: rgba(237, 153, 49, 1)"
                v-if="item.scoreStage === '亚健康'"
              >
                {{ item.scoreStage }}
              </div>
              <div
                class="item-status"
                style="color: red"
                v-if="item.scoreStage === '疾病'"
              >
                {{ item.scoreStage }}
              </div>
              <div class="item-desc">
                <div class="desc-top">
                  <div class="desc-title">火警数</div>
                  <div class="desc-num">
                    {{ (item.alertInfoVO || {}).processedCount }}/{{
                      (item.alertInfoVO || {}).totalCount
                    }}
                  </div>
                </div>
                <div class="desc-bottom">
                  <div class="desc-title">故障数</div>
                  <div class="desc-num">
                    {{ (item.deviceInfoVO || {}).processedCount }}/{{
                      (item.deviceInfoVO || {}).todayExCount
                    }}
                  </div>
                </div>
              </div>
            </div>
            <div class="line" v-if="idx < healthInfo.length - 1"></div>
          </div>
        </vue-seamless-scroll>
      </div>
    </div>
    <div class="left-two admin" v-if="isQuanAdmin">
      <div class="title-bg" v-if="current === 1">
        本日数据
        <div class="title-icon" />
      </div>
      <div class="title-bg" v-if="current === 2">
        本周数据
        <div class="title-icon" />
      </div>
      <div class="title-bg" v-if="current === 3">
        本月数据
        <div class="title-icon" />
      </div>
      <div class="title-bg" v-if="current === 4">
        本年数据
        <div class="title-icon" />
      </div>
      <div class="content">
        <div class="arrow-left" @click="prev">
          <img src="../assets/images/index/left.png" />
        </div>
        <VueSlickCarousel
          :arrows="false"
          :dots="true"
          ref="carousel"
          v-bind="settings"
        >
          <div class="main" v-for="item in [1, 2, 3, 4]">
            <div class="main-left" v-if="healthInfo.scoreStage === '完美'">
              <img src="../assets/images/index/perfect.png" />
            </div>
            <div class="main-left" v-if="healthInfo.scoreStage === '健康'">
              <img src="../assets/images/index/health.png" />
            </div>
            <div class="main-left" v-if="healthInfo.scoreStage === '亚健康'">
              <img src="../assets/images/index/yajiankang.png" />
            </div>
            <div class="main-left" v-if="healthInfo.scoreStage === '疾病'">
              <img src="../assets/images/index/jibing.png" />
            </div>
            <div class="main-right">
              <div class="right-item">
                <div class="item-top">火警数</div>
                <div class="item-num">
                  {{
                    healthInfo.alertInfoVO &&
                    healthInfo.alertInfoVO.processedCount
                  }}/{{
                    healthInfo.alertInfoVO && healthInfo.alertInfoVO.totalCount
                  }}
                </div>
              </div>
              <div class="right-item">
                <div class="item-top">故障数</div>
                <div class="item-num">
                  {{
                    healthInfo.deviceInfoVO &&
                    healthInfo.deviceInfoVO.processedCount
                  }}/{{
                    healthInfo.deviceInfoVO &&
                    healthInfo.deviceInfoVO.todayExCount
                  }}
                </div>
              </div>
              <div class="right-item">
                <div class="item-top">接警处理平均时效</div>
                <div class="item-num">
                  {{ healthInfo.prescriptionTime }}
                </div>
              </div>
            </div>
          </div>
        </VueSlickCarousel>
        <div class="arrow-right" @click="next">
          <img src="../assets/images/index/right.png" />
        </div>
      </div>
    </div>
    <!-- <div class="left-three">
      <div class="title-bg">设备设施<div class="title-icon"/></div>
      <div class="content">
        <div class="arrow-left"><img src="../assets/images/index/left.png"/></div>
        <VueSlickCarousel :arrows="true" :dots="true" v-bind="{ ...settings, autoplay: false }" v-if="deviceInfo.sysRunList.length">
          <div class="main" v-for="(item, idx) in getList(deviceInfo.sysRunList)" :key="idx" v-if="(idx % 2) === 0">
            <div class="main-top">
              <div class="main-item" v-for="(each, index) in item" :key="each.id">
                <div class="item-title">{{ each.deviceType }}</div>
                <div class="item">在线：{{ each.goodNum }}</div>
                <div class="item">总数：{{ each.totalNum }}</div>
              </div>
            </div>
            <div class="main-bottom">
              <div
                class="main-item"
                v-for="(each, index) in getList(deviceInfo.sysRunList)[idx + 1]"
                :key="each.id"
                v-if="idx < getList(deviceInfo.sysRunList).length - 1"
              >
                <div class="item-title">{{ each.deviceType }}</div>
                <div class="item">在线：{{ each.goodNum }}</div>
                <div class="item">总数：{{ each.totalNum }}</div>
              </div>
            </div>
          </div>
        </VueSlickCarousel>
        <div class="arrow-right"><img src="../assets/images/index/right.png"/></div>
      </div>
    </div> -->
    <div class="left-three">
      <div class="title-bg">
        设备设施
        <div class="title-icon" />
      </div>
      <div class="content" v-infinite-scroll="load">
        <vue-seamless-scroll
          :data="deviceInfo.sysRunList"
          ref="seamlessScroll2"
          :class-option="defaultOption2"
        >
        <!-- <div v-if="item.goodNum"> -->
          <div v-if="item.goodNum" v-for="(item, idx) in deviceInfo.sysRunList" :key="item.id">
            <div class="item">
              <div class="item-title">{{ item.deviceType }}</div>
              <div class="item-desc">
                <div class="desc-top">
                  <!-- <div class="desc-title">火警数</div> -->
                  <div class="desc-num">
                    {{ item.goodNum }}/{{ item.totalNum }}
                    <!-- {{ (item.alertInfoVO || {}).processedCount }}/{{
                      (item.alertInfoVO || {}).totalCount
                    }} -->
                  </div>
                </div>
                <!-- <div class="desc-bottom">
                  <div class="desc-title">故障数</div>
                  <div class="desc-num">
                    {{ (item.deviceInfoVO || {}).processedCount }}/{{
                      (item.deviceInfoVO || {}).todayExCount
                    }}
                  </div>
                </div> -->
              </div>
            </div>
            <div class="line" v-if="idx < healthInfo.length - 1"></div>
          </div>
        <!-- </div> -->
          <!-- <div>
            <div
              class="t1"
              v-if="item.goodNum"
              v-for="(item, idx) in deviceInfo.sysRunList"
              :key="item.id"
            >
              <div>{{ item.deviceType }}</div>
              <div>{{ item.goodNum }}/{{ item.totalNum }}</div>
            </div>
          </div> -->
        </vue-seamless-scroll>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { getStore } from "@/utils/store";
import VueSlickCarousel from "vue-slick-carousel";
import vueSeamlessScroll from "vue-seamless-scroll";

let lastIndex = 3;
export default {
  data() {
    return {
      current: 1,
      healthInfo: [],
      announcementInfo: [],
      deviceInfo: {
        sysRunList: [],
      },
      settings: {
        dots: true,
        dotsClass: "slick-dots custom-dot-class",
        edgeFriction: 0.35,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
      },
      baseInfo: {
        totalNum: 0,
        selfNum: 0,
        leaseNum: 0,
      },
      defaultOption: {
        autoPlay: true,
        step: 0.5, // 数值越大速度滚动越快
        limitMoveNum: 6, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      },
      defaultOption2: {
        autoPlay: true,
        step: 0.5, // 数值越大速度滚动越快
        limitMoveNum: 8, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      },
    };
  },
  props: ["groupParkId", "isQuanAdmin"],
  mounted() {
    const { getHealthData, getData, getDeviceInfo, getAnnouncementInfo } = this;
    getHealthData();
    getData();
    getAnnouncementInfo();
    getDeviceInfo();
    const timer = setInterval(() => {
      getHealthData();
      getData();
      getDeviceInfo();
      getAnnouncementInfo();
    }, 1000 * 60);
    this.$once("hook:beforeDestroy", function () {
      clearInterval(timer);
    });
  },
  watch: {
    groupParkId() {
      this.getHealthData();
      this.getData();
      this.getDeviceInfo();
      this.getAnnouncementInfo();
    },
  },
  components: {
    VueSlickCarousel,
    vueSeamlessScroll,
  },
  methods: {
    load() {
      this.healthInfo = [...this.healthInfo, ...this.healthInfo];
    },
    prev() {
      const curIdx = this.current > 1 ? this.current - 1 : 4;
      this.getHealthData(curIdx, () => {
        this.$refs.carousel.prev();
        this.current = this.current > 1 ? this.current - 1 : 4;
      });
    },
    next() {
      const curIdx = this.current < 4 ? this.current + 1 : 1;
      this.getHealthData(curIdx, () => {
        this.$refs.carousel.next();
        this.current = this.current < 4 ? this.current + 1 : 1;
      });
    },
    getAnnouncementInfo() {
      this.$api
        .get(
          "/longwang-service-biz/bigScreenDisplay/parkAnnouncementInfo?groupParkId=" +
          (this.groupParkId || "")
        )
        .then(({ data }) => {
          this.announcementInfo = data;
        });
    },
    getArray(array, index, index1) {
      let idx = index === undefined ? lastIndex : index;
      let idx1 = index1 === undefined ? array.length : index1;
      let list = array.filter((item, i) => i >= idx && i < idx1);
      const nameLength = list.map((_) => _.deviceType).join(",").length;
      if (idx === 0 && nameLength >= 18) {
        list = array.filter((item, i) => i >= idx && i < 2);
        lastIndex = 1;
      } else {
        lastIndex = 2;
      }
      return list;
    },
    getList(array) {
      let list = [];
      let index = 0;
      let length = Math.ceil(array.length / 2);
      array.map((item, idx) => {
        const nameLength = list[index]
          ? list[index].map((_) => _.deviceType).join(",").length
          : 0;
        if (
          idx < 2 * (index + 1) &&
          idx >= 2 * index &&
          length >= 0 &&
          nameLength <= 10
        ) {
          list[index] = list[index] ? list[index].concat([item]) : [item];
        } else {
          length = length - 1;
          index = index + 1;
          list[index] = list[index] ? list[index].concat([item]) : [item];
        }
        return list;
      });
      return list;
    },
    getData() {
      if (!this.isQuanAdmin) {
        this.$api
          .get(
            "/longwang-service-biz/bigScreenDisplay/accessNum?groupParkId=" +
            (this.groupParkId || "")
          )
          .then(({ data }) => {
            this.baseInfo = data || {};
          });
      }
    },
    getHealthData(curIdx, callback) {
      this.$api
        .get(
          "/longwang-service-biz/bigScreenDisplay/healthInfo?groupParkId=" +
            (this.groupParkId || "") +
            "&dateType=" +
            (curIdx || this.current)
        )
        .then(({ data }) => {
          this.healthInfo = data || [];
          if (callback) callback();
        });
    },
    getDeviceInfo() {
      this.$api
        .get(
          "/longwang-service-biz/bigScreenDisplay/deviceInfo?groupParkId=" +
            (this.groupParkId || "")
        )
        .then(({ data = {} }) => {
          this.deviceInfo = {
            ...data,
            sysRunList: (data.sysRunList || []).filter(
              (item) => +item.goodNum > 0
            ),
          };
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.left {
  position: absolute;
  left: 38px;
  width: 400px;
  height: 100%;
  color: #fff;
  z-index: 999;
  .left-one {
    position: absolute;
    top: 85px;
    width: 400px;
    height: 330px;
    .content {
      display: flex;
      align-items: center;
      justify-content: space-around;
      // background: url('../assets/images/index/left-two-bg1.png') no-repeat;
      // background-size: cover;
      width: 405px;
      height: 272px;
      @keyframes bounce-down {
        25% {
          transform: translateY(-10px);
        }
        50%,
        100% {
          transform: translateY(0);
        }
        75% {
          transform: translateY(10px);
        }
      }
      .content-img {
        img {
          animation: bounce-down 3s linear infinite;
        }
      }

      .content-img {
        width: 72px;
        height: 82px;
        img {
          width: 72px;
          height: 82px;
        }
      }
      .content-item {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .content-num {
        font-size: 36px;
        line-height: 54px;
        height: 27px;
        font-family: AlibabaPuHuiTiBold;
        font-weight: bold;
        color: #ffffff;
        margin: 29px 0 27px 0;
      }
      .content-title {
        font-size: 14px;
        font-family: AlibabaPuHuiTiBold;
        font-weight: bold;
        color: #e2e2e2;
      }
    }
    background-size: cover;
  }
  .left-one-one {
    position: absolute;
    top: 85px;
    width: 400px;
    height: 330px;
    .content {
      display: flex;
      align-items: center;
      width: 383px;
      height: 211px;
      justify-content: space-between;
      margin: 0 auto;
      overflow: hidden;
      margin-top: 31px;
      .info {
        font-size: 14px;
        font-family: AlibabaPuHuiTi;
        font-weight: 400;
        color: #c6c6c6;
        text-align: left;
        line-height: 1.9;
        height: 211px;
        overflow: auto;
        flex: 1;
      }
      .arrow-left,
      .arrow-right {
        cursor: pointer;
        img {
          width: 15px;
          height: 20px;
        }
      }
    }
  }
  .left-two {
    position: absolute;
    top: 415px;
    width: 400px;
    height: 330px;
    .content {
      width: 350px;
      height: 249px;
      // background: url('../assets/images/index/left-two-bg.png') no-repeat;
      // background-size: cover;
      margin: 0 auto;
      overflow-y: scroll;
      overflow-x: visible;
      margin-top: 18px;
      .item {
        display: flex;
        width: 340px;
        align-items: center;
        margin: 0 auto;
        padding: 8.5px 0;
        margin-right: 10px;
        justify-content: space-between;
        .item-title {
          font-size: 16px;
          font-family: AlibabaPuHuiTi;
          color: #ffffff;
          width: 40%;
        }
        .item-status {
          font-size: 16px;
          font-family: AlibabaPuHuiTi;
          font-weight: 400;
          color: #6bb168;
        }
        .item-desc {
          .desc-top,
          .desc-bottom {
            display: flex;
            .desc-num {
              color: rgba(237, 153, 49, 1);
              margin-left: 12px;
              font-family: DINAlternate-Bold;
            }
          }
          .desc-bottom {
            margin-top: 5px;
          }
        }
      }
      .line {
        width: 340px;
        margin: 0 auto;
        height: 1px;
        border: 1px solid rgba(255, 255, 255, 0.25);
      }
    }
  }
  .admin {
    position: absolute;
    top: 415px;
    width: 405px;
    height: 367px;
    .content {
      width: 405px;
      // height: 267px;
      // background: url('../assets/images/index/left-two-bg1.png') no-repeat;
      // background-size: cover;
      overflow: initial;
      margin-top: 0;
      display: flex;
      justify-content: space-around;
      align-items: center;
      position: relative;
      .main {
        width: 100%;
        margin-top: 50px;
        display: flex !important;
        align-items: center;
        justify-content: space-around;
        .main-left {
          width: 154px;
          height: 164px;
          img {
            width: 100%;
            width: 100%;
          }
        }
        .main-right {
          height: 164px;
          justify-content: space-around;
          display: flex;
          flex-direction: column;
          .right-item {
            text-align: left;
            .item-title {
              font-size: 14px;
              font-family: AlibabaPuHuiTi;
              font-weight: 400;
              color: #c6c6c6;
            }
            .item-num {
              font-size: 18px;
              font-family: DIN;
              font-weight: 400;
              color: #fffefe;
            }
          }
        }
      }
      .arrow-left,
      .arrow-right {
        cursor: pointer;
        img {
          width: 15px;
          height: 20px;
        }
      }
      .dotList {
        display: flex;
        justify-content: center;
        position: absolute;
        bottom: 0px;
        width: 100%;
        margin-left: -6px;
        .dot {
          width: 8px;
          height: 8px;
          background: #666666;
          border-radius: 50%;
          margin-left: 12px;
          &.active {
            background: #30f6fd;
          }
        }
      }
    }
  }
  .left-three {
    position: absolute;
    top: 745px;
    width: 400px;
    height: 330px;
    // overflow-y: auto;
    .content {
      // width: 405px;
      width: 350px;
      height: 249px;
      margin: 0 auto;
      overflow-y: scroll;
      overflow-x: visible;
      margin-top: 18px;

      // height: 267px;
      // background: url('../assets/images/index/left-two-bg1.png') no-repeat;
      // background-size: cover;
      
      // overflow: hidden;
      // margin-top: 0;
      // display: flex;
      // justify-content: space-around;
      // align-items: center;
      // position: relative;
       .item {
        display: flex;
        width: 340px;
        align-items: center;
        margin: 0 auto;
        padding: 8.5px 0;
        margin-right: 10px;
        justify-content: space-between;
        .item-title {
          font-size: 16px;
          font-family: AlibabaPuHuiTi;
          color: #ffffff;
          width: 50%;
        }
        .item-desc {
          .desc-top,
          .desc-bottom {
            display: flex;
            .desc-num {
              color: rgba(237, 153, 49, 1);
              // margin-right: 5px;
              font-family: DINAlternate-Bold;
            }
          }
          .desc-bottom {
            margin-top: 5px;
          }
        }
      }
      .line {
        width: 340px;
        margin: 0 auto;
        height: 1px;
        border: 1px solid rgba(255, 255, 255, 0.25);
      }
      .main {
        width: 100%;
        margin-top: 50px;
        display: flex !important;
        align-items: center;
        justify-content: space-around;
        .main-left {
          width: 154px;
          height: 164px;
          img {
            width: 100%;
            width: 100%;
          }
        }
        .main-right {
          height: 164px;
          justify-content: space-around;
          display: flex;
          flex-direction: column;
          .right-item {
            text-align: left;
            .item-title {
              font-size: 14px;
              font-family: AlibabaPuHuiTi;
              font-weight: 400;
              color: #c6c6c6;
            }
            .item-num {
              font-size: 18px;
              font-family: DIN;
              font-weight: 400;
              color: #fffefe;
            }
          }
        }
      }
      .arrow-left,
      .arrow-right {
        cursor: pointer;
        img {
          width: 15px;
          height: 20px;
        }
      }
      .dotList {
        display: flex;
        justify-content: center;
        position: absolute;
        bottom: 0px;
        width: 100%;
        margin-left: -6px;
        .dot {
          width: 8px;
          height: 8px;
          background: #666666;
          border-radius: 50%;
          margin-left: 12px;
          &.active {
            background: #30f6fd;
          }
        }
      }
    }
  }
}
.t1 {
  display: inline-block;
  width: 50%;
  margin: 0;
  padding-top: 24px;
  > div:nth-child(1) {
    color: #fda52f;
    margin-bottom: 6px;
  }
  > div:nth-child(2) {
  }
}
</style>
