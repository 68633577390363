<template>
  <div class="right">
    <div class="right-one" v-if="!isQuanAdmin">
      <div class="title-bg">公告<div class="title-icon"/></div>
      <div class="content" v-if="announcementInfo.length">
        <div class="arrow-left" v-if="announcementInfo.length > 1"><img src="../assets/images/index/left.png"/></div>
        <VueSlickCarousel :arrows="true" :dots="false" v-bind="settings" v-if="announcementInfo.length">
          <div class="info" v-for="item in announcementInfo" :key="item.id" v-html="item.content"></div>
        </VueSlickCarousel>
        <div class="arrow-right" v-if="announcementInfo.length > 1"><img src="../assets/images/index/right.png"/></div>
      </div>
      <div class="content" v-else>
        <div class="info">暂无公告</div>
      </div>
    </div>
    <!-- 详情 -->
    <div class="right-two-one" v-if="detailInfo.show" :style="{'top': isQuanAdmin ? '25px' : '340px'}">
      <div class="title-bg">
        <span>{{ detailInfo.type === 1 ? '告警情况' : '重点部位情况'}}<span style="color: rgba(2, 232, 254, 1);margin-left: 30px;">{{detailInfo.contextShowStatusDesc}}</span></span>
        <div class="title-close-icon cursor" @click="(e) => showDetail(e, { show: false })"/>
       </div>
       <div class="content" :style="{'height': isQuanAdmin ? '756px' : '656px'}">
         <div class="item-one">
           <img src="../assets/images/index/icon1.png"/>
           <div class="desc">{{detailInfo.taskTitle}}</div>
         </div>
         <div class="item-two">
           <img src="../assets/images/index/icon2.png"/>
           <div class="desc">{{detailInfo.taskPosition}}</div>
         </div>
         <div class="item-three">
           <img src="../assets/images/index/icon3.png"/>
           <div class="desc">{{detailInfo.createTime}}</div>
         </div>
         <div class="line" />
         <div v-if="detailInfo.contextShowStatusDesc !== '待提交'">
           <div class="reason-title" v-if="detailInfo.contextShowStatusDesc !== '待提交'">告警原因</div>
           <div class="reason" v-if="detailInfo.contextShowStatusDesc !== '待提交'">{{detailInfo.taskContent}}</div>
           <div class="line"  v-if="detailInfo.contextShowStatusDesc !== '待提交'"/>
           <div class="reason-title" v-if="detailInfo.taskPictures && detailInfo.taskPictures.length">图片预览</div>
           <div class="img" v-if="detailInfo.taskPictures && detailInfo.taskPictures.length">
             <el-image
                style="width: 100%; height: 100%"
                :src="(detailInfo.taskPictures || [])[0]"
                :preview-src-list="detailInfo.taskPictures">
             </el-image>
           </div>
           <div class="item-title">设备编号：<span class="item-number">{{detailInfo.deviceId}}</span></div>
           <div class="item-title">设备回路号：<span class="item-number">{{detailInfo.deviceCircuitNumber}}</span></div>
           <div class="item-title">安装位置：<span class="item-number">{{detailInfo.deviceName}}</span></div>
           <div class="item-title">设备状态：<span class="item-number">{{detailInfo.deviceStatusDesc}}</span></div>
           <div class="item-title">当前状态：<span class="item-number">{{detailInfo.deviceCurrentV}}</span></div>
         </div>
         <div v-else>
           <div class="item-title1">重点部位详情</div>
           <div class="item-title">重点单位报告计划名称：<span class="item-number">{{detailInfo.planTitle}}</span></div>
           <div class="item-title">开始时间：<span class="item-number">{{detailInfo.startTime}}</span></div>
           <div class="item-title">结束时间：<span class="item-number">{{detailInfo.endTime}}</span></div>
           <div class="item-title">任务周期：<span class="item-number">{{detailInfo.contextState}}</span></div>
           <div class="item-title">计划内容：<span class="item-number">{{detailInfo.planContent}}</span></div>
         </div>
       </div>
    </div>
     <!-- //详情 -->
    <!-- 右侧-告警情况列表 -->
    <div
      class="right-two cursor"
      @click="(e) => $refs.rightTwoDialog.show(e)"
      v-if="!detailInfo.show"
      :style="{'top': isQuanAdmin ? '25px' : '340px'}"
    >
      <div class="title-bg">告警情况<div class="title-icon"/></div>
      <div class="content" :style="{'height': getHeight()}">
        <div class="content-top">
          <div class="content-title mr-50">今日告警<div class="num">{{alertTask.totalNum}}</div></div>
          <div class="content-title" @click="(e) => $refs.rightTwoDialog.show(e, 6)">已完结<div class="num">{{alertTask.okNum}}</div></div>
        </div>
        <div class="main" style="height: 84%">
          <vue-seamless-scroll :data="alertTask.bigScreenTask" ref="seamlessScroll" :class-option="defaultOption">
            <div class="content-item cursor" v-for="item in alertTask.bigScreenTask.filter(item => item.nodeName !== '已完结')" :key="item.id" @click="(e) => showDetail(e, { ...item, show: true })">
              <div class="item-title">
                <div class="content-title">{{item.title}}</div>
                <div class="item-status">{{item.nodeName}}</div>
              </div>
              <div class="item-address">{{item.taskPosition}}</div>
              <div class="item-time">{{item.createTime}}</div>
            </div>
          </vue-seamless-scroll>
        </div>
      </div>
    </div>
    <!-- //右侧-告警情况列表 -->
    <!-- 右侧-重点部位告警列表 -->
    <div
      class="right-three cursor"
      v-if="!detailInfo.show && (baseInfo.keyPartsAlertNum > 0 || baseInfo.exNum > 0)"
      :style="{'top': isQuanAdmin ? '521px' : '745px'}"
    >
      <div class="title-bg" @click="() => $refs.rightThreeDialog.show()">重点部位<div class="title-icon"/></div>
      <div class="content" :style="{'height': isQuanAdmin ? '390px' : '200px'}">
        <div class="content-top" @click="() => $refs.rightThreeDialog.show()">
          <div class="content-title mr-50">今日重点部位任务<div class="num">{{baseInfo.keyPartsAlertNum}}</div></div>
          <div class="content-title">已完结<div class="num">{{baseInfo.exNum}}</div></div>
        </div>
        <!-- <div class="main" v-infinite-scroll="load"> -->
        <div class="main" :style="{'height': isQuanAdmin ? '390px' : '220px'}">
          <vue-seamless-scroll :data="baseInfo.keyParts" ref="seamlessScroll" :class-option="defaultOption">
            <div
              :class="['content-item', (item.latestReportAbnormal) ? 'active' : '']"
              v-for="item in baseInfo.keyParts"
              :key="item.id"
              @click="(e) => (item.latestReportAbnormal || item.nodeName === '待提交') && showDetail1(e, { ...item, show: true })"
            >
              <div class="item-title">
                <div class="content-title">{{item.name}}</div>
                <div class="item-status-error" v-if="item.latestReportAbnormal">{{item.nodeName}}</div>
                <div class="item-status" v-else>{{item.nodeName}}</div>
              </div>
              <div class="item-address">{{item.address}}</div>
              <div class="item-time">
                <div class="item-desc one">{{item.useFunction}}</div>
                <div class="item-desc two">{{item.riskPoint}}</div>
              </div>
            </div>
          </vue-seamless-scroll>
        </div>
      </div>
    </div>
    <!-- //右侧-重点部位告警列表 -->
    <!-- 点击之后的告警情况列表弹窗 -->
    <right-two-dialog ref="rightTwoDialog" :showDetail="showDetail" :info="alertTask" />
    <!-- 点击之后的重点部位列表弹窗 -->
    <right-three-dialog ref="rightThreeDialog" :showDetail="showDetail1" :info="baseInfo" />
    <!--  点位管理弹窗  -->
    <el-dialog
        title="点位管理"
        :visible.sync="pointDetailInfo.show"
        top="15vh"
        :append-to-body="true"
        :show-close="false"
        :close-on-click-modal="false"
        custom-class="point-dialog"
        width="54%">
      <div slot="title"></div>
      <point-examine ref="pointExamine" :detail-info="detailInfo" @goBack="goPointBack"></point-examine>
    </el-dialog>
  </div>
</template>

<script>
import {getPointManagementInfo} from './point-examine/api/index'
import moment from "moment";
import VueSlickCarousel from 'vue-slick-carousel'
import vueSeamlessScroll from 'vue-seamless-scroll'
import rightTwoDialog from "./dialog/right-two";
import rightThreeDialog from "./dialog/right-three";
import PointExamine from "./point-examine/index"

export default {
  data() {
    return {
      announcementInfo: [],
      detailInfo: {
        show: false
      },
      pointDetailInfo: {
        show: false
      },
      baseInfo: {
        keyParts: []
      },
      alertTask: {
        bigScreenTask: [],
      },
      settings: {
        "dots": false,
        "dotsClass": "slick-dots custom-dot-class",
        "edgeFriction": 0.35,
        "infinite": true,
        "speed": 500,
        "slidesToShow": 1,
        "slidesToScroll": 1
      },
      defaultOption: {
        autoPlay: true,
        step: 0.5, // 数值越大速度滚动越快
        limitMoveNum: 4, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
      }
    };
  },
  components: {
    rightTwoDialog,
    rightThreeDialog,
    vueSeamlessScroll,
    VueSlickCarousel,
    PointExamine
  },
  props: ['groupParkId', 'isQuanAdmin'],
  watch: {
    groupParkId() {
      this.getData()
      this.getAlertTask()
      this.getAnnouncementInfo()
    }
  },
  mounted() {
    const { getAlertTask, getData, getAnnouncementInfo } = this
    getAlertTask()
    getData()
    getAnnouncementInfo()
    const timer = setInterval(() => { getAlertTask(); getData(); getAnnouncementInfo(); }, 1000 * 60);
    this.$once('hook:beforeDestroy',function () {
      clearInterval(timer);
    });

    // this.test();
  },
  methods: {
    /*test(){
      this.detailInfo = {show: true}
      this.$nextTick(function () {
        this.$refs.pointExamine.reqPointManagementInfo();
      })
    },*/
    showDetail(e, info) {
      e.stopPropagation()
      if (info.show) {
        this.$api.get(`/longwang-service-biz/context/${info.id}?taskType=1&groupParkId=` + this.groupParkId)
         .then(({ data }) => {
           this.detailInfo = { ...data, show: true, type: 1 }

           // 点位管理弹窗
           let params = data.contextId
           getPointManagementInfo(params).then(res => {
             console.log("===getPointManagementInfo====")
             // console.log(res)
             if(res.success){
               let planeCode = res.data.coordinate;
               let imageSrc = res.data.icon;
               let pointManagements = res.data.managements;
               if(pointManagements.length > 0){
                 let pointDetailInfo = {show: true,planeCode,imageSrc,pointManagements}
                 this.pointDetailInfo = pointDetailInfo;
                 this.$nextTick(function () {
                   this.$refs.pointExamine.reqPointManagementInfo(pointDetailInfo);
                 })
               }else {
                 this.$message.error("该设备未关联点位！");
               }
             }
           }).catch(err => {
             console.log(err)
           })
         })
      } else {
        this.detailInfo = info
      }
    },
    getHeight() {
      if (this.baseInfo.keyPartsAlertNum > 0 || this.baseInfo.exNum > 0) {
        if (this.isQuanAdmin) {
          return '430px'
        } else {
          return '330px'
        }
        this.defaultOption = ({
          ...this.defaultOption,
          limitMoveNum: 8,
        })
      } else {
        if (this.isQuanAdmin) {
          return '920px'
        } else {
          return '820px'
        }
      }
    },
    showDetail1(e, info) {
      e.stopPropagation()
      if (info.show) {
        this.$api.get(`/longwang-service-biz/context/${info.contextId}?taskType=3&groupParkId=` + (this.groupParkId || ""))
         .then(({ data }) => {
           this.detailInfo = { ...data, show: true, type: 2 }
         })
      } else {
        this.detailInfo = info
      }
    },
    getData() {
      this.$api.get('/longwang-service-biz/bigScreenDisplay/keyPartsInfo?groupParkId=' + (this.groupParkId || ""))
       .then(({ data }) => {
         this.baseInfo = data
       })
    },
    getAlertTask() { // 右侧-告警情况列表
      this.$api.get('/longwang-service-biz/bigScreenDisplay/alertTask?groupParkId=' + (this.groupParkId || ""))
       .then(({ data }) => {
         this.alertTask = data
       })
    },
    getAnnouncementInfo() {
      this.$api.get('/longwang-service-biz/bigScreenDisplay/parkAnnouncementInfo?groupParkId=' + (this.groupParkId || ""))
       .then(({ data }) => {
         this.announcementInfo = data || []
       })
    },
    load() {
      this.baseInfo.keyParts = [ ...this.baseInfo.keyParts, ...this.baseInfo.keyParts ]
    },
    goPointBack(){
      this.pointDetailInfo = {show: false};
      this.detailInfo = {show: false};
    }
  }
}
</script>

<style lang="scss" scoped>
  .right {
    position: absolute;
    right: 38px;
    width: 400px;
    color: #fff;
    z-index: 999;
    height: 100%;
    top: 60px;
    .right-one {
      position: absolute;
      top: 25px;
      width: 400px;
      height: 330px;
      // background: url('../assets/images/index/left-two-bg1.png') no-repeat;
      // background-size: cover;
      .content {
        display: flex;
        align-items: center;
        width: 383px;
        height: 161px;
        justify-content: space-between;
        margin: 0 auto;
        overflow: hidden;
        margin-top: 31px;
        .info {
          font-size: 14px;
          font-family: AlibabaPuHuiTi;
          font-weight: 400;
          color: #C6C6C6;
          text-align: left;
          line-height: 1.9;
          height: 161px;
          overflow: auto;
          flex: 1;
        }
        .arrow-left, .arrow-right {
          cursor: pointer;
          img {
            width: 15px;
            height: 20px;
          }
        }
      }
    }
    .right-two-one {
      position: absolute;
      top: 25px;
      width: 400px;
      height: 330px;

      .content {
        text-align: left;
        width: 385px;
        margin-top: 18px;
        height: 656px;
        background: rgba(26, 40, 85, 0.3);
        border: 1px solid;
        overflow: auto;
        padding: 27px 32px;
        border-image: linear-gradient(42deg, rgba(122, 236, 255, 0.3), rgba(27, 184, 211, 0.3), rgba(122, 236, 255, 0.3)) 10 10;
        .line {
          width: 328px;
          height: 1px;
          margin: 15px 0;
          border: 1px solid rgba(255, 255, 255, 0.25);
        }
        .reason-title {
          font-size: 18px;
          font-family: Source Han Sans SC;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 30px;
          text-shadow: 0px 3px 3px rgba(0, 0, 0, 0.3);
        }
        .img {
          width: 333px;
          height: 199px;
          margin-bottom: 10px;
        }
        .item-title1 {
          font-size: 18px;
          font-family: Source Han Sans SC;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 30px;
          margin-bottom: 15px;
          text-shadow: 0px 3px 3px rgba(0, 0, 0, 0.3);
        }
        .item-title {
          font-size: 16px;
          font-family: SourceHanSansSC-Regular;
          color: #fff;
          font-weight: 500;
          line-height: 30px;
          .item-number {
            font-family: PingFang SC;
            font-weight: 400;
            color: #fff;
          }
        }
        .reason {
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 300;
          color: #FFFFFF;
          line-height: 30px;
          text-shadow: 0px 3px 3px rgba(0, 0, 0, 0.3);
        }
        .item-one {
          img {
            width: 16px;
            height: 18px;
            margin-right: 8px;
          }
          .desc {
            font-size: 18px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #FDA52F;
          }
          display: flex;
        }
        .item-two {
          img {
            width: 11px;
            height: 14px;
            margin-right: 8px;
          }
          .desc {
            font-size: 14px;
            font-family: PingFangSC-Light;
            font-weight: 300;
            color: #FFFFFF;
            margin-top: 16px;
            margin-bottom: 16px;
            text-shadow: 0px 3px 3px rgba(0, 0, 0, 0.3);
          }
          display: flex;
          align-items: center;
        }
        .item-three {
          img {
            width: 12px;
            height: 12px;
            margin-right: 8px;
          }
          .desc {
            font-size: 14px;
            font-family: PingFangSC-Light;
            font-weight: 300;
            color: #FFFFFF;
            text-shadow: 0px 3px 3px rgba(0, 0, 0, 0.3);
          }
          align-items: center;
          display: flex;
        }
      }
    }
    .right-two {
      position: absolute;
      top: 25px;
      width: 400px;
      height: 430px;
      .content {
        margin-top: 19px;
        overflow: auto;
        height: 340px;
        .main {
          height: 370px;
          overflow-y: auto;
        }
        .content-top {
          font-size: 14px;
          font-family: AlibabaPuHuiTi;
          font-weight: 400;
          display: flex;
          align-items: center;
          color: #FFFFFF;
          line-height: 30px;
          margin-top: 20px;
          margin-bottom: 19px;
          padding-left: 20px;
        }
        .content-title {
          display: flex;
          align-items: center;
        }
        .num {
          font-size: 18px;
          font-family: DIN-Regular;
          font-weight: 400;
          color: #FDA52F;
          margin-left: 11px;
        }
        .content-item {
          width: 385px;
          margin-bottom: 10px;
          text-align: left;
          padding: 13px 17px;
          border: 1px solid #7aecff;
          background: url('../assets/images/index/grey.png') no-repeat;
          background-size: cover;
          // background: rgba(26, 40, 85, 0.3);
          .item-title {
            display: flex;
            align-items: baseline;
            justify-content: space-between;
            font-family: AlibabaPuHuiTiBold;
            font-weight: bold;
            .item-status {
              color: #02E8FE;
            }
          }
          .content-title {
            flex: 1;
          }
          .item-address {
            font-size: 14px;
            font-family: AlibabaPuHuiTi;
            font-weight: 400;
            color: #C6C6C6;
            margin-top: 11px;
            margin-bottom: 8px;
          }
          .item-time {
            font-size: 14px;
            font-family: DIN-Regular;
            font-weight: 400;
            color: #C6C6C6;
          }
        }
      }
    }
    .right-three {
      position: absolute;
      top: 521px;
      width: 400px;
      height: 390px;
      .content {
        margin-top: 19px;
        .content-top {
          font-size: 14px;
          font-family: AlibabaPuHuiTi;
          font-weight: 400;
          display: flex;
          align-items: center;
          color: #FFFFFF;
          line-height: 30px;
          margin-top: 20px;
          margin-bottom: 19px;
          padding-left: 20px;
        }
        .content-title {
          display: flex;
          align-items: center;
          line-height: 1;
        }
        .num {
          font-size: 18px;
          font-family: DIN-Regular;
          font-weight: 400;
          color: #FDA52F;
          margin-left: 11px;
        }
        .main {
          position: relative;
          height: 220px;
          overflow-y: auto;
          .arrow-left, .arrow-right {
            cursor: pointer;
            img {
              width: 15px;
              height: 20px;
            }
          }
        }
        .content-item {
          width: 370px;
          text-align: left;
          padding: 13px 17px 0 17px;
          background: url('../assets/images/index/right-two-bg.png') no-repeat;
          background-size: cover;
          background: rgba(26, 40, 85, 0.3);
          border: 1px solid;
          // margin: 0 auto;
          margin-bottom: 10px;
          border-image: linear-gradient(42deg, #7AECFF, #1BB8D3, #7AECFF) 10 10;
          .item-title {
            align-items: baseline;
            display: flex;
            justify-content: space-between;
            .item-status {
              color: #02E8FE;
            }
            .item-status-error {
              color: rgba(255, 30, 30, 1);
            }
          }
          .content-title {
            flex: 1;
          }
          .item-time {
            display: flex;
            justify-content: space-between;
            .item-desc {
              font-size: 14px;
              font-family: PingFang;
              font-weight: 400;
              color: #E2E2E2;
              &.one {
                width: fit-content;
                margin-right: 10px;
              }
              &.two {
                flex: 1;
              }
            }
          }
          .item-address {
            font-size: 14px;
            font-family: AlibabaPuHuiTi;
            font-weight: 400;
            color: #C6C6C6;
            line-height: 1;
            margin-top: 11px;
            margin-bottom: 8px;
          }
          &.active {
            background: rgba(171, 4, 4, 0.3);
            border: 1px solid;
            border-image: linear-gradient(42deg, #FF7A7A, #D31B1B, #FF7A7A) 10 10;
          }
        }
      }
    }
  }
</style>
<style lang="scss">
.point-dialog{
  background: transparent !important;
  box-shadow: 0 0 0 transparent !important;
  // background: #00B4FF;
  display: flex;
  justify-content: center;
  .el-dialog__header{
    display: none;
  }
  .el-dialog__body{
    padding: 0;
  }
}
</style>